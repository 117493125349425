/* Custom select box styling based on https://github.com/filamentgroup/select-css/ */

/* Container used for styling the custom select, the buttom class below adds the
 * bg gradient, corners, etc. */
@use "sass:math";
@use '../settings' as *;

.custom-select {
	position: relative;
	display: inline-block;
	z-index: 1;
}

/* This is the native select, we're making everything but the text invisible so
 * we can see the button styles in the wrapper */
.custom-select select {
	height: (math.div(44, 16) * 1em);
	border-radius: 4px;
	background: #fff;
	border: 2px solid $grey-secondary;
	display: inline-block;
	max-width: 100%;

	padding: 0 (math.div(13, 16) * 1em);

	font-family: $helvetica;

	width: 100%;
	margin: 0;
	outline: none;

	text-overflow: ellipsis;

	/* Prefixed box-sizing rules necessary for older browsers */
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	/* Font size must be 16px to prevent iOS page zoom on focus */
	font-size: 1em;
	color: #333;

	option {
		padding: 0.25em 0.5em 0.25em 1em;
	}
}

/* Custom arrow sits on top of the select - could be an image, SVG, icon font,
 * etc. or the arrow could just baked into the bg image on the select. */
.custom-select::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: 14px;
	z-index: 2;
	/* These hacks make the select behind the arrow clickable in some browsers */
	pointer-events: none;
	display: none;

	background: url(../../images/icons/arrow-down.svg) center center no-repeat;
	background-size: contain;
	width: 10px;

}

@supports ( -webkit-appearance: none ) or ( appearance: none ) or ( ( -moz-appearance: none ) and ( mask-type: alpha ) ) {

	/* Firefox <= 34 has a false positive on @supports( -moz-appearance: none )
	 * @supports ( mask-type: alpha ) is Firefox 35+
	 */

	/* Show custom arrow */
	.custom-select::after {
		display: block;
	}

	/* Remove select styling */
	.custom-select select {
		padding-right: 2em; /* Match-01 */
		/* inside @supports so that iOS <= 8 display the native arrow */
		background: none; /* Match-04 */
		/* inside @supports so that Android <= 4.3 display the native arrow */
		//border: 1px solid transparent; /* Match-05 */

		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.custom-select select:focus {
		border-color: $purple; /* Match-03 */
	}
}

/* Firefox focus has odd artifacts around the text, this kills that. See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-focusring */
.custom-select select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #000;
}

/* IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance. Targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5 - looking for better ways to achieve this targeting */
/* The second rule removes the odd blue bg color behind the text in the select button in IE 10/11 and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.custom-select select::-ms-expand {
		display: none;
	}
	.custom-select select:focus {
		border-color: $purple; /* Match-03 */
	}
	.custom-select select:focus::-ms-value {
		background: transparent;
		color: $grey; /* Match-02*/
	}
	.custom-select select {
		padding-right: 2em; /* Match-01 */
		background: none; /* Match-04 */
	}
	.custom-select::after {
		display: block;
	}
}
