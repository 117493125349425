@use "sass:math";
@use "sass:list";
@use '../settings' as *;

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;

	&::before {
		display: block;
		content: "";
	}

	.embed-responsive-item,
	iframe,
	embed,
	object,
	video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}

// Generates a class name based on the aspect ratios defined in $embed-responsive-aspect-ratios
// The ratio's are then used to calculate the percentage needed to maintain that aspect ratio.
// Generated classed are:
// - .embed-responsive-21by9
// - .embed-responsive-16by9
// - .embed-responsive-4by3
// - .embed-responsive-1by1
@each $embed-responsive-aspect-ratio in $embed-responsive-aspect-ratios {
	$embed-responsive-aspect-ratio-x: list.nth($embed-responsive-aspect-ratio, 1);
	$embed-responsive-aspect-ratio-y: list.nth($embed-responsive-aspect-ratio, 2);

	.embed-responsive-#{$embed-responsive-aspect-ratio-x}by#{$embed-responsive-aspect-ratio-y} {
		&::before {
			padding-top: math.percentage(math.div($embed-responsive-aspect-ratio-y, $embed-responsive-aspect-ratio-x));
		}
	}
}
