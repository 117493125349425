/* header */
.header a:not(.button) {
  color: #461d53;
}

@media only screen and (max-width: 767px) {
  .header-hamburger {
    color: #461d53;
  }
}

.header-hamburger-icon:before, .header-hamburger-icon:after,
.header-hamburger-icon span {
  background: #461d53;
  color: #461d53;
}

.header-logo img {
  color: #461d53;
}

.header-nav a {
  color: #461d53;
}
.header-nav a span:after {
  background: #7d6086;
}
@media only screen and (max-width: 767px) {
  .header-nav ul {
    background: #ffffff;
  }
  .header-nav li:first-child {
    border-top-color: #461d53;
  }
}

.header-user {
  color: #461d53;
}
@media only screen and (min-width: 768px) and (max-width: 1023px), only screen and (min-width: 1024px) {
  .header-user {
    border-color: #7d6086;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px), only screen and (min-width: 1024px) {
  .header-user .header-user--mynovi {
    color: #ffffff;
    background: #7d6086;
    border-color: #7d6086;
  }
}
@media only screen and (max-width: 767px) {
  .header-user .header-user--mynovi {
    color: #7d6086;
  }
}

.header-shopping-info {
  background: #461d53;
  color: #ffffff;
}

.header-language button, .header-language label {
  color: #333333;
  border-left-color: #cccccc;
}

.header-lang__select-overlay {
  background: #ffffff;
}

/* ordertabs */
.header-tabs li {
  color: #ffffff;
  background: #583464;
}
.header-tabs li:hover {
  background: rgba(88, 52, 100, 0.5);
}
.header-tabs li a:not(.button) {
  color: #ffffff;
}
.header-tabs li.active {
  color: #461d53;
  background: #ffffff;
}
.header-tabs li.active a {
  color: #461d53;
}

.header-tabs--select select {
  background: #ffffff;
  border-color: #ffffff;
  color: #461d53;
}
.header-tabs--select select:focus, .header-tabs--select select:active {
  color: #461d53;
  border-color: #cccccc;
}

.header-orderswitcher {
  background: #ffffff;
}
.header-orderswitcher .custom-select {
  background: #ffffff;
  color: #461d53 !important;
}
.header-orderswitcher .custom-select select {
  color: #461d53 !important;
  border-color: #ffffff;
}
.header-orderswitcher .custom-select:after {
  color: #461d53;
}

/* Footer */
.footer {
  color: #ffffff;
  background: #4d4d4d;
}
.footer h2 {
  color: #ffffff;
}

.footer-disclaimer-menu {
  color: #333333;
  background: #b3b3b3;
}

/* Login page */
@media screen and (min-width: 768px) {
  .login-block {
    border-color: #eaeaea;
  }
}
@media screen and (max-width: 767px) {
  .login-block {
    border-bottom-color: #eaeaea;
  }
}

.form-show-password {
  color: #919191;
}
.form-show-password:focus, .form-show-password:hover {
  color: #461d53;
}

/* Data table */
.mynovi-data-table th, .mynovi-data-table td {
  border-bottom-color: #eaeaea;
}

@media screen and (max-width: 767px) {
  .mynovi-data-table.mynovi-data-table--pending tr {
    border-bottom-color: #eaeaea;
  }
  .mynovi-data-table.mynovi-data-table--pending tr.mynovi-data-table__loading + tr, .mynovi-data-table.mynovi-data-table--pending tr:first-child {
    border-top-color: #eaeaea;
  }
  .mynovi-data-table.mynovi-data-table--pending .mynovi-data-table_datelocation h4, .mynovi-data-table.mynovi-data-table--pending .mynovi-data-table_datelocation p {
    color: #461d53;
  }
}

@media screen and (max-width: 767px) {
  .mynovi-data-table.mynovi-data-table--pricelists tr,
  .homepage-pricelists .mynovi-data-table tr,
  .homepage-pending-orders .mynovi-data-table tr,
  .mynovi-data-table.mynovi-data-table--history tr {
    border-bottom-color: #eaeaea;
  }
  .mynovi-data-table.mynovi-data-table--pricelists tr.mynovi-data-table__loading + tr, .mynovi-data-table.mynovi-data-table--pricelists tr:first-child,
  .homepage-pricelists .mynovi-data-table tr.mynovi-data-table__loading + tr,
  .homepage-pricelists .mynovi-data-table tr:first-child,
  .homepage-pending-orders .mynovi-data-table tr.mynovi-data-table__loading + tr,
  .homepage-pending-orders .mynovi-data-table tr:first-child,
  .mynovi-data-table.mynovi-data-table--history tr.mynovi-data-table__loading + tr,
  .mynovi-data-table.mynovi-data-table--history tr:first-child {
    border-top-color: #eaeaea;
  }
  .mynovi-data-table.mynovi-data-table--pricelists .mynovi-data-table_datelocation h4, .mynovi-data-table.mynovi-data-table--pricelists .mynovi-data-table_datelocation p,
  .homepage-pricelists .mynovi-data-table .mynovi-data-table_datelocation h4,
  .homepage-pricelists .mynovi-data-table .mynovi-data-table_datelocation p,
  .homepage-pending-orders .mynovi-data-table .mynovi-data-table_datelocation h4,
  .homepage-pending-orders .mynovi-data-table .mynovi-data-table_datelocation p,
  .mynovi-data-table.mynovi-data-table--history .mynovi-data-table_datelocation h4,
  .mynovi-data-table.mynovi-data-table--history .mynovi-data-table_datelocation p {
    color: #461d53;
  }
  .mynovi-data-table.mynovi-data-table--pricelists .mynovi-data-table_name-and-availability h4,
  .homepage-pricelists .mynovi-data-table .mynovi-data-table_name-and-availability h4,
  .homepage-pending-orders .mynovi-data-table .mynovi-data-table_name-and-availability h4,
  .mynovi-data-table.mynovi-data-table--history .mynovi-data-table_name-and-availability h4 {
    color: #461d53;
  }
  .mynovi-data-table.mynovi-data-table--pricelists .mynovi-data-table_name-and-availability p,
  .homepage-pricelists .mynovi-data-table .mynovi-data-table_name-and-availability p,
  .homepage-pending-orders .mynovi-data-table .mynovi-data-table_name-and-availability p,
  .mynovi-data-table.mynovi-data-table--history .mynovi-data-table_name-and-availability p {
    color: #333333;
  }
}

/* Overview */
.overview-grid .product-label__icon {
  background: #ffffff;
}

.overview-product .discount-tag {
  color: #ffffff;
  background: #69b938;
}

@media screen and (min-width: 768px) {
  .overview-grid .overview-product {
    border-color: #eaeaea;
  }
  .overview-product_image img:before {
    background: #f2f2f2;
    color: rgba(70, 29, 83, 0.5);
  }
  .overview-product_title {
    color: #461d53;
  }
  .overview-product_subtitle {
    color: #333333;
  }
  .overview-product_size {
    color: #919191;
  }
  .overview-list .overview-product {
    border-top-color: #eaeaea;
  }
  .overview-list .overview-product_title {
    color: #333333;
  }
  .overview-list .overview-product_title a {
    color: #333333;
  }
  .overview-list .overview-product_title .overview-product_subtitle {
    color: #919191;
  }
  .overview-list .overview-product_size {
    color: #919191;
  }
  .overview-list .overview-product_size svg.icon-potheight,
  .overview-list .overview-product_size svg.icon-potsize {
    color: #9a9a9a;
    fill: #9a9a9a;
  }
  .overview-list .overview-product_message {
    color: #000000;
  }
}
.original-price {
  color: #919191;
}

@media screen and (max-width: 767px) {
  .overview-product {
    border-top-color: #eaeaea;
  }
  .overview-product:last-child {
    border-bottom-color: #eaeaea;
  }
  .cms-article .overview-product_title,
  .overview-product_title {
    color: #333333;
  }
  .cms-article .overview-product_title a,
  .overview-product_title a {
    color: #333333;
  }
  .cms-article .overview-product_title .overview-product_subtitle,
  .overview-product_title .overview-product_subtitle {
    color: #919191;
  }
  .overview-product_size {
    color: #919191;
  }
  .overview-product_size svg.icon-potheight,
  .overview-product_size svg.icon-potsize {
    color: #9a9a9a;
    fill: #9a9a9a;
  }
  .overview-product_message {
    color: #000000;
  }
}
.product-buy-option-bar--disabled .product-buy-option-bar_price {
  color: #919191;
}
.product-buy-option-bar_price .original-price,
.product-buy-option-bar_price s {
  color: #919191;
}
.product-buy-option-bar_input input,
.product-buy-option-bar_input .quantity-input_input {
  border-color: #cccccc;
}
.product-buy-option-bar_input button,
.product-buy-option-bar_input .quantity-input_minus,
.product-buy-option-bar_input .quantity-input_plus {
  border-color: #cccccc;
  background: #eaeaea;
  color: #666666;
}
.product-buy-option-bar_input button:active,
.product-buy-option-bar_input .quantity-input_minus:active,
.product-buy-option-bar_input .quantity-input_plus:active {
  background-color: #cccccc;
}

.loading-overlay {
  background: rgba(255, 255, 255, 0.65);
}
.loading-overlay:before {
  border-color: rgba(204, 204, 204, 0.51);
  border-top-color: #461d53;
}

.loading-overlay.loading-overlay--datatable {
  background: rgba(255, 255, 255, 0.65);
}

.product-detail_related-products, .product-detail_product-order-history {
  border-top-color: #eaeaea;
}

/* My Novi */
.select-checkout-method h3 svg {
  color: #461d53;
}
.select-checkout-method h3 .tag {
  background: #69b938;
  color: #ffffff;
}
.select-checkout-method li .icon-check {
  color: #69b938;
}

.shoppingcart-confirmation-message .icon-check-wrapper-rounded {
  background: #69b938;
}
.shoppingcart-confirmation-message .icon-check-wrapper-rounded .icon-check {
  color: #ffffff;
}

/* Pagination */
.cms-pagination li,
.ng2-pagination.ng2-pagination.ng2-pagination.ng2-pagination li {
  color: #461d53;
  background: #ffffff;
}
.cms-pagination li.pagination-next a, .cms-pagination li.pagination-previous a,
.ng2-pagination.ng2-pagination.ng2-pagination.ng2-pagination li.pagination-next a,
.ng2-pagination.ng2-pagination.ng2-pagination.ng2-pagination li.pagination-previous a {
  border-color: rgb(153, 132, 160);
}

.pagenumber-input-container input[type=number].ng-touched:not(:focus) {
  border-color: #cccccc;
}
.pagenumber-input-container input[type=number]:focus {
  border-color: #461d53;
}
.pagenumber-input-container button {
  color: #919191;
}
.pagenumber-input-container button:focus, .pagenumber-input-container button:hover {
  color: #461d53;
}