@use "sass:math";
@use '../settings' as *;
@use '../layout' as *;

.grid-container {
	display: grid;
	grid-gap: $gutter;

	&.grid-nav {
		grid-gap: 0;
		grid-template-columns: minmax(0, max-content) minmax(100px, max-content) minmax(max-content, auto) minmax(min-content, max-content);
		vertical-align: middle;
		padding: 0 $gutter-mobile;

		.inner-grid {
			grid-auto-flow: column;
			grid-gap: 0;
			align-items: center;
			@include grid-custom(0, repeat(3, minmax(0, auto)));
		}

		.grid-item {
			align-self: center;
		}
	}
}

.header {
	.row {
		padding: 0 22px;

		@media #{$mq-menu-small} {
			padding: 0 $gutter-mobile;
		}
	}
}

.header-hamburger {
	display: none;
	cursor: pointer;

	@media #{$mq-menu-small} {
		display: block;
		background: none;
		border: none;
		text-decoration: none;
		line-height: 60px;
		height: 60px;
		padding: 0 14px;
		min-width: (math.div(19, 16) * 1em) + (math.div(28, 16) * 1em);
		margin: 0 10px 0 -14px;
		position: relative;
	}
}

.header-hamburger-icon {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	width: 19px;
	padding: 7px 0;

	&:before,
	&:after,
	span {
		content: '';
		display: block;
		height: 3px;
		width: 100%;
		padding: 0;
		overflow: hidden;
		border-radius: 1.5px;
		transition: opacity 0.15s ease-out, transform 0.2s ease-in-out;
	}

	span {
		font-size: 1px;
		transform-origin: 0 50%;
	}

	&:before {
		position: absolute;
		top: 0;
		transform-origin: 50% 0;
	}

	&:after {
		bottom: 0;
		position: absolute;
		transform-origin: 50% 100%;
	}

	.mobile-menu-active & {
		span {
			opacity: 0;
			transform: scaleX(0);
			transform-origin: 50% 0;
		}

		&:before {
			transform: translateY(8px) rotate(45deg);
			transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
			transition-duration: 0.35s;
		}

		&:after {
			transform: translateY(-7px) rotate(-45deg);
			transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
			transition-duration: 0.35s;
		}
	}

}

.header-logo {
	display: block;
	padding-right: $gutter;
	margin-top: 12px;
	margin-bottom: 17px;

	img {
		font-weight: bold;
		font-size: 28px;
	}

	@media #{$mq-menu-small} {
		margin-top: 10px;
		margin-bottom: 8px;
		img {
			font-size: 24px;
			width: 100px;
			height: 41px;
		}
	}
}

.header-nav {
	font-size: (math.div(20, 16) * 1em); //font-size: 20px;
	line-height: 1.2em;
	font-weight: 300;
	padding-top: 0;

	@media only screen and (min-width: 768px) and (max-width: 1120px) {
		font-size: 1em;
		line-height: 1.2em;
		padding-top: 0;
	}

	ul, li {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		display: inline-block;

		a {
			padding-right: 1em;
		}

		&:last-of-type {
			a {
				padding-right: 0;
			}
		}
	}

	a {
		display: block;
		position: relative;
		text-decoration: none;

		@media screen and (max-width: 840px) {
			padding: 0 .75em
		}

		@media screen and (max-width: 800px) {
			padding: 0 .6em
		}

		span {
			display: block;
			position: relative;
			padding: (5 * 0.05 * 1em) 0;

			@media #{$mq-menu-medium} {
				padding-bottom: (math.div(2, 16) * 1em)
			}

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 2px;
				opacity: 0;
			}
		}

		&:hover {
			span:after {
				opacity: 1;
			}
		}
	}

	li.active > a,
	a.router-link-active {
		span:after {
			opacity: 1;
		}
	}

	@media #{$mq-menu-small} {
		padding-top: 0;
		float: none;
		clear: both;

		margin-left: -$gutter-mobile;
		margin-right: -$gutter-mobile;

		font-size: 1em; //font-size: 16px;
		font-weight: normal;

		position: absolute;
		z-index: 10;
		width: 100%;
		left: $gutter-mobile;
		top: 61px;

		&:after {
			content: '';
			height: 8px;
			display: block;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
			opacity: 0;
			position: absolute;
			top: 100%;
			width: 100%;

			.mobile-menu-active & {
				opacity: 0.5;
			}
		}

		ul {
			display: none;
			background: #ffffff;

			.mobile-menu-active & {
				display: block;
			}
		}

		li {
			display: block;
			animation: menuItemFadeIn 0.2s ease-out;

			@for $i from 2 through 6 {
				&:nth-child(#{$i}) {
					animation-duration: #{(0.2s + 0.075s * $i)};
				}
			}

			&:first-child {
				border-top-style: solid;
				border-top-width: (math.div(3, 16) * 1em);

				a {
					padding-top: (math.div(20, 16)*1em);
				}
			}
		}

		li > div:first-child,
		a {
			padding: (math.div(13, 16)*1em) (math.div(15, 16)*1em);

			span {
				display: inline-block;
				padding: 0;
				margin: 0;
			}
		}
	}

	li.mobile-lang {
		display: none;

		@media #{$mq-menu-small} {
			display: block;
			position: relative;
		}

		div {
			padding-top: math.div(20, 16)*1em;
			padding-bottom: math.div(18, 16)*1em;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			height: 2px;
			background: #eaeaea;
			left: 14px;
			right: 14px;
		}
	}
}

.header-user {
	display: block;

	@media #{$mq-menu-medium}, #{$mq-menu-large} {
		border-style: solid;
		border-width: 2px;
		font-weight: bold;
		text-decoration: none;
		padding: (math.div(10, 16) * 1em) (math.div(20, 16) * 1em);
		margin: 0;
		border-radius: 4px;

		img,
		svg {
			margin-right: 3px;
			width: 15px;
		}

		span {
			display: inline-block;
			vertical-align: middle;
		}
	}

	@media #{$mq-menu-medium} {
		span {
			display: none;
		}
	}

	@media #{$mq-menu-small} {
		span {
			display: none;
		}
		padding: 0 10px;
	}

	&.header-user--mynovi {
		@media #{$mq-menu-medium}, #{$mq-menu-large} {
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
	}

}

.header-shopping-info {
	min-height: 8px;

	@media #{$mq-menu-small} {
		overflow: hidden;
	}

	&--preload-minheight {
		min-height: 54px;
	}
}

@keyframes menuItemFadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1
	}
}

@media screen and (min-width: 768px) {
	.header.sticky {
		padding-bottom: 54px;

		.header-shopping-info {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 200;
			min-height: 54px;
			box-shadow: 0 0 5px rgba(0, 0, 0, .5);
		}
	}
}

.header-language {
	position: relative;

	button, label {
		color: $grey;
		font-weight: normal;
		background: none;
		-webkit-appearance: none;
		border: none;
		border-radius: 0;
		line-height: math.div(24, 16);
		padding: (math.div(10, 16) * 1em) (math.div(20, 16) * 1em);
		border-left: 1px solid $grey-secondary;

		@media #{$mq-menu-medium} {
			margin-right: 5px;
			padding-left: (math.div(20, 16) * 1em);
		}
	}

	.header-lang-select {
		display: none;
		position: absolute;
	}

	.header-lang__iso {
		display: none;
		text-transform: uppercase;
	}

	@media #{$mq-menu-small} {
		button, label {
			border: none;
			line-height: normal;
			padding: 0;
			margin: 0 math.div(10, 16)*1em;
		}

		.header-language__select-overlay {
			top: 0;
			height: 100%;
		}

		.header-lang__iso {
			display: inline;
		}
		.header-lang__full {
			display: none;
		}
	}
}

.header-lang__select-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
	background: #ffffff; // Background color needs to be set for chrome on osx, otherwise the height of select won't change
	cursor: pointer;

	.header-language & {
		top: 20px;
		height: calc(100% - 20px);
		padding: (math.div(10, 16) * 1em) (math.div(20, 16) * 1em);
	}
}
