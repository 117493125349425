@use '../settings' as *;

%favorite-button {
	display: inline-block;
	text-align: center;
	background: #ffffff;
	color: #cccccc;
	.active & {
		color: $red-notice;
	}

	// Target only devices with mouse and trackpad. This was done because the click on the favorite button is very
	// scoped. This resulted in taps on touch devices always registeren as their initial hover state. Making it seem
	// like the component state was not updated until you clicked outsite of the button.
	@media (hover: hover) and (pointer: fine) {
		&:hover {
			color: $red-notice;
		}
		.active & {
			color: $red-notice;
			&:hover {
				color: #cccccc;
			}
		}
	}
}

.product-title__wrapper {
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
	align-items: flex-start;
	margin: 10px 2px;

	@media screen and (min-width: 768px) {
		margin: 10px 14px 0;
	}
}

.overview-list {
	.product-title__wrapper {
		margin: 0;
	}
}

.product-favorite-button__icon {
	@extend %favorite-button;
	vertical-align: middle;
	width: 41px;
	height: 41px;
	line-height: 41px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
	border-radius: 4px;
}

.productitem-favorite-button__icon {
	@extend %favorite-button;
	vertical-align: top;
}
