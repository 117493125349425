@use "sass:math";
@use '../settings' as *;

.login-block {
	padding: 20px 0 0;
	margin: 0 0 40px;

	@media screen and (min-width: 768px) {
		border: 2px solid #eaeaea;
		border-radius: 4px;
		padding: 20px 24px 16px;
		margin: 30px 0 40px;
	}

	@media screen and (max-width: 767px) {
		border-bottom: 2px solid #eaeaea;

		.button-bar--right {
			text-align: left;
			margin: 26px 0;
		}

		&.login-block--register {
			padding-top: 0;
		}
	}
}

.form-show-password {
	height: (math.div(44, 16) * 1em);
	position: absolute;
	top: 0;
	right: 2px;
	padding: 0 14px;
	background: none;
	border: none;
	cursor: pointer;
}

.form-forgot-password {
	text-align: right;
	line-height: (math.div(22, 16) * 1em);
	padding: (math.div(22, 16) * 0.5em) 0;

	@media screen and (min-width: 768px) {
		margin-bottom: -0.5em;
	}

	@media screen and (max-width: 767px) {
		position: absolute;
		bottom: 100%;
		right: 0;
		padding-bottom: (math.div(8, 16) * 1em);
	}
}

.form-back-to-login {
	margin: 1em 0 !important;

	@media screen and (min-width: 768px) {
		margin: 0 0 0.5em !important;
	}
}
