@use 'settings' as *;

/* header */
.header {
	a:not(.button) {
		color: $purple;
	}
}

.header-hamburger {
	@media #{$mq-menu-small} {
		color: $purple;
	}
}

.header-hamburger-icon {
	&:before,
	&:after,
	span {
		background: $purple;
		color: $purple;
	}
}

.header-logo {
	img {
		color: $purple;
	}
}

.header-nav {
	a {
		color: $purple;

		span {
			&:after {
				background: $purple-secondary;
			}
		}
	}

	@media #{$mq-menu-small} {
		ul {
			background: #ffffff;
		}

		li {
			&:first-child {
				border-top-color: $purple;
			}
		}
	}
}

.header-user {
	color: $purple;

	@media #{$mq-menu-medium}, #{$mq-menu-large} {
		border-color: $purple-secondary;
	}

	.header-user--mynovi {
		@media #{$mq-menu-medium}, #{$mq-menu-large} {
			color: #ffffff;
			background: #7d6086;
			border-color: #7d6086;
		}

		@media #{$mq-menu-small} {
			color: #7d6086;
		}
	}
}

.header-shopping-info {
	background: $purple;
	color: #ffffff;
}

.header-language {
	button, label {
		color: $grey;
		border-left-color: $grey-secondary;
	}
}

.header-lang__select-overlay {
	background: #ffffff; // Background color needs to be set for chrome on osx, otherwise the height of select won't change
}

/* ordertabs */
.header-tabs {
	li {
		color: #ffffff;
		background: #583464;

		&:hover {
			background: rgba(88, 52, 100, 0.5);
		}

		a:not(.button) {
			color: #ffffff;
		}

		&.active {
			color: $purple;
			background: #ffffff;

			a {
				color: $purple;
			}
		}
	}
}

.header-tabs--select {
	select {
		background: #ffffff;
		border-color: #ffffff;
		color: $purple;

		&:focus,
		&:active {
			color: $purple;
			border-color: #cccccc;
		}
	}
}

.header-orderswitcher {
	background: #ffffff;

	.custom-select {
		background: #ffffff;
		color: $purple !important;

		select {
			color: $purple !important;
			border-color: #ffffff;
		}

		&:after {
			color: $purple;
		}
	}
}

/* Footer */
.footer {
	color: #ffffff;
	background: #4d4d4d;

	h2 {
		color: #ffffff;
	}
}

.footer-disclaimer-menu {
	color: #333333;
	background: #b3b3b3;
}

/* Login page */
.login-block {
	@media screen and (min-width: 768px) {
		border-color: #eaeaea;
	}

	@media screen and (max-width: 767px) {
		border-bottom-color: #eaeaea;
	}
}

.form-show-password {
	color: #919191;

	&:focus,
	&:hover {
		color: $purple;
	}
}

/* Data table */
.mynovi-data-table {
	th, td {
		border-bottom-color: #eaeaea;
	}
}

.mynovi-data-table.mynovi-data-table--pending {
	@media screen and (max-width: 767px) {
		tr {
			border-bottom-color: #eaeaea;

			&.mynovi-data-table__loading + tr,
			&:first-child {
				border-top-color: #eaeaea;
			}
		}

		.mynovi-data-table_datelocation {
			h4, p {
				color: $purple;
			}
		}
	}
}

.mynovi-data-table.mynovi-data-table--pricelists,
.homepage-pricelists .mynovi-data-table,
.homepage-pending-orders .mynovi-data-table,
.mynovi-data-table.mynovi-data-table--history {
	@media screen and (max-width: 767px) {
		tr {
			border-bottom-color: #eaeaea;

			&.mynovi-data-table__loading + tr,
			&:first-child {
				border-top-color: #eaeaea;
			}
		}

		.mynovi-data-table_datelocation {
			h4, p {
				color: $purple;
			}
		}

		.mynovi-data-table_name-and-availability {
			h4 {
				color: $purple;
			}

			p {
				color: #333333;
			}
		}
	}
}

/* Overview */
.overview-grid {
	.product-label__icon {
		background: #ffffff;
	}
}

.overview-product {
	.discount-tag {
		color: #ffffff;
		background: $green;
	}
}

@media screen and (min-width: 768px) {
	.overview-grid {
		.overview-product {
			border-color: #eaeaea;
		}
	}

	.overview-product_image {
		img {
			&:before {
				background: #f2f2f2;
				color: rgba(70, 29, 83, 0.5);
			}
		}
	}

	.overview-product_title {
		color: $purple;
	}

	.overview-product_subtitle {
		color: #333333;
	}

	.overview-product_size {
		color: #919191;
	}

	.overview-list {
		.overview-product {
			border-top-color: #eaeaea;
		}

		.overview-product_title {
			color: #333333;

			a {
				color: #333333;
			}

			.overview-product_subtitle {
				color: #919191;
			}
		}

		.overview-product_size {
			color: #919191;

			svg.icon-potheight,
			svg.icon-potsize {
				color: #9a9a9a;
				fill: #9a9a9a;
			}
		}

		.overview-product_message {
			color: #000000;
		}
	}
}

.original-price {
	color: #919191;
}

@media screen and (max-width: 767px) {
	.overview-product {
		border-top-color: #eaeaea;

		&:last-child {
			border-bottom-color: #eaeaea;
		}
	}

	.cms-article .overview-product_title,
	.overview-product_title {
		color: #333333;

		a {
			color: #333333;
		}

		.overview-product_subtitle {
			color: #919191;
		}
	}

	.overview-product_size {
		color: #919191;

		svg.icon-potheight,
		svg.icon-potsize {
			color: #9a9a9a;
			fill: #9a9a9a;
		}
	}

	.overview-product_message {
		color: #000000;
	}
}

.product-buy-option-bar {
	&_price {
		.product-buy-option-bar--disabled & {
			color: #919191;
		}

		.original-price,
		s {
			color: #919191;
		}
	}

	&_input {
		input,
		.quantity-input_input {
			border-color: #cccccc;
		}

		button,
		.quantity-input_minus,
		.quantity-input_plus {
			border-color: #cccccc;
			background: #eaeaea;
			color: #666666;

			&:active {
				background-color: #cccccc;
			}
		}
	}
}

.loading-overlay {
	&:before {
		border-color: rgba(204, 204, 204, 0.51);
		border-top-color: $purple;
	}

	background: rgba(255, 255, 255, 0.65);
}

.loading-overlay.loading-overlay--datatable {
	background: rgba(255, 255, 255, 0.65);
}

.product-detail {
	&_related-products, &_product-order-history {
		border-top-color: #eaeaea;
	}
}

/* My Novi */
.select-checkout-method {
	h3 {
		svg {
			color: $purple;
		}

		.tag {
			background: $green;
			color: #ffffff;
		}
	}

	li {
		.icon-check {
			color: $green;
		}
	}
}

.shoppingcart-confirmation-message {
	.icon-check-wrapper-rounded {
		background: $green;

		.icon-check {
			color: #ffffff;
		}
	}
}

/* Pagination */
.cms-pagination,
.ng2-pagination.ng2-pagination.ng2-pagination.ng2-pagination {
	li {
		color: $purple;
		background: #ffffff;

		&.pagination-next,
		&.pagination-previous {
			a {
				border-color: rgb(153, 132, 160);
			}
		}
	}
}

.pagenumber-input-container {
	input[type="number"] {
		&.ng-touched:not(:focus) {
			border-color: #cccccc;
		}

		&:focus {
			border-color: #461d53;
		}
	}

	button {
		color: #919191;

		&:focus,
		&:hover {
			color: $purple;
		}
	}
}
