@use "sass:math";
@use '../settings' as *;

.product-page-bar {
	&:after {
		content: '';
		display: table;
		clear: both;
	}

	margin: 0 0 60px;
	order: 10;

	@media screen and (max-width: 767px) {
		margin-top: 20px;
	}

	&_top {

		&:after {
			content: '';
			display: table;
			clear: both;
		}

		margin: 20px 0 0;
		order: 2 !important;

		@media screen and (max-width: 767px) {
			margin: 0 0 20px;
		}
	}
}


.pagination-overview-count {
	float: left;
	clear: left;

	text-align: center;

	min-width: math.div(40, 16)*1em;
	height: math.div(40, 16)*1em;
	line-height: math.div(38, 16)*1em;
	padding-top: 2px;
}

.custom-template {
	float: right;
	clear: right;

	@media only screen and (max-width: 375px) {
		max-width: 141px;
		width: 141px;
	}
}

.cms-pagination,
.ng2-pagination.ng2-pagination.ng2-pagination.ng2-pagination {
	display: inline-flex;
	margin: 0;
	padding: 0;
	text-align: center;

	li {
		display: inline-block;
		min-width: math.div(32, 16)*1em;
		height: math.div(32, 16)*1em;
		line-height: math.div(32, 16)*1em;
		text-align: center;
		padding: 0;
		color: $purple;
		border-radius: 4px;
		background: #ffffff;

		div,
		a {
			color: inherit;
			text-decoration: none;
			padding: 0;
			display: inline-block;
			background: transparent;
			min-width: math.div(32, 16)*1em;
			height: math.div(32, 16)*1em;
			line-height: math.div(32, 16)*1em;
		}

		a {
			cursor: pointer;
		}

		&:not(.current) {
			display: none;
		}

		&.pagination-next,
		&.pagination-previous {
			display: block;
			height: 2em;
			line-height: 2em;

			a {
				border-radius: 4px;
				height: 2em;
				line-height: 1.8em;
				border: 2px solid rgb(153, 132, 160);
			}
		}

		&.pagination-next {
			margin: 0 0 0 2px;
		}

		&.pagination-previous {
			margin: 0 2px 0 0;
		}
	}

	li.current {
		font-weight: bold;
		background: none !important;

		div {
			line-height: 1.3em;
		}
	}

	.disabled {
		visibility: hidden;
	}

	.pagination-next.pagination-next,
	.pagination-previous.pagination-previous {
		a:before,
		a:after {
			content: '';
			display: none;
		}
	}

	@media screen and (min-width: 768px) {
		margin: 0;
		float: right;
		clear: right;
		padding-left: 1em;

		li {
			&:not(.current) {
				display: block;
			}

			&.pagination-next,
			&.pagination-previous {
				display: block;
				height: 2em;
				line-height: 1.8em;
				padding-top: 0;

				a {
					border-radius: 0;
					height: 2em;
					line-height: 1.8em;
					border: none;
				}
			}
		}
	}
}

.cms-pagination-container {
	width: 100%;
	margin: 40px 0;
	text-align: center;
}

.cms-pagination.cms-pagination {
	text-align: center;
	float: none;
	padding: 0;
}

.pagenumber-input-container {
	position: relative;
	max-width: 6.9em;

	input[type="number"] {
		display: block;
		width: 100%;
		height: 2em;
		padding: 0 0 0 0.5em;
		font-weight: normal;
		-moz-appearance: textfield;

		&.ng-touched:not(:focus) {
			border-color: #cccccc;
		}
		&:focus {
			border-color: #461d53;
		}


		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	button {
		position: absolute;
		top: 2px;
		bottom: 2px;
		right: 2px;
		padding: 0 14px;
		background: none;
		border: none;
		color: #919191;
		cursor: pointer;
		transition: color 0.1s ease-out;

		&:focus,
		&:hover {
			color: $purple;
		}

		svg {
			vertical-align: middle;
			position: relative;
			top: -1px;
		}
	}
}
