@use 'sass:math';
@use 'settings' as *;

@mixin grid-1($gutter) {
    grid-template-columns: 1fr;

    @media #{$mq-menu-small} {
        .grid-item {
            .button {
                display: block;
                width: 100%;

                + .button {
                    margin-top: $gutter;
                }
            }
        }
    }
}

@mixin grid-custom($gutter, $cols) {
    grid-template-columns: $cols;
    grid-gap: $gutter;
}

.grid-container {
    display: grid;
    grid-gap: $gutter;

    &.grid-1 {
        @include grid-1($gutter);
    }

    &.grid-2 {
        grid-template-columns: repeat(2, 1fr);
    }

    &.grid-3 {
        grid-template-columns: repeat(3, 1fr);
    }

    &.grid-nav {
        grid-gap: 0;
        grid-template-columns:
            minmax(0, max-content) minmax(100px, max-content) minmax(
                max-content,
                auto
            )
            minmax(min-content, max-content);
        vertical-align: middle;
        padding: 0 $gutter-mobile;

        .inner-grid {
            grid-auto-flow: column;
            grid-gap: 0;
            align-items: center;
            @include grid-custom(0, repeat(3, minmax(0, auto)));
        }

        .grid-item {
            align-self: center;
        }
    }

    &.grid-table-filter-bar {
        grid-template-columns: minmax(min-content, max-content) auto;
        grid-gap: 0;
    }

    &.grid-custom-2 {
        @include grid-custom($gutter, repeat(2, auto));
    }

    &.grid-custom-6 {
        @include grid-custom($gutter, repeat(5, auto) 1fr);
    }
}

@media #{$mq-menu-small} {
    .grid-container {
        &.grid-1-mobile {
            @include grid-1(math.div($gutter, 2));
        }

        &.grid-custom-6,
        &.grid-custom-2 {
            grid-template-columns: [labels] auto [controls] 1fr;
            grid-gap: $gutter-mobile;
        }

        &.grid-5-mobile {
            grid-template-columns: 1fr [labels] auto [controls] auto;
        }
    }
}

.table-filter-bar {
    margin-top: math.div($gutter, 2);
    margin-bottom: math.div($gutter, 2);
    font-size: math.div(13, 16) * 1em;

    .factorForm,
    .sortOnForm {
        > label {
            cursor: default;
            font-weight: normal;
            margin: 0;
        }
    }

    .factorForm > input {
        max-width: 5em;
    }
}

@media #{$mq-menu-small} {
    .table-filter-bar {
        &.start-1 {
            .grid-item:nth-child(2) {
                grid-column-start: 1;
                grid-row-start: 1;
            }
        }

        .factorForm {
            margin-top: $gutter-mobile;
        }

        .factorForm > label,
        .sortOnForm > label {
            grid-column: labels;
            min-width: 80px;
            margin: 0;
        }

        .factorForm > input,
        .factorForm .custom-select,
        .sortOnForm:not(.grid-5-mobile) > input,
        .sortOnForm:not(.grid-5-mobile) .custom-select {
            grid-column: controls;
            width: 66%;
            max-width: inherit;
            margin: 0;
        }

        .sortOnForm:not(.grid-5-mobile) .custom-select {
            width: inherit;
        }

        .factorForm > button {
            grid-column-start: 2;
            max-width: 66%;
        }
    }
}

.align-right {
    text-align: right;
}

.mt-6 {
    margin-top: 1.5rem;
}
