@use "sass:math";
@use '../settings' as *;

.cms-article.cms-article--blog-template {
	position: relative;
	z-index: 2;
	margin: 0;
	padding: 1em 0;
	background: #ffffff;

	h1 {
		color: #333333;
	}

	.inspiration-flex__image {
		margin: 0;
	}

	@media screen and (min-width: 768px) {
		border-radius: 4px;
		padding-bottom: 15px;
		padding-top: 0;
		overflow: hidden;
		margin-bottom: 15px;
	}
}

.cms-section {
	&--blog {
		margin: 50px 0;
	}
}

.cms-article__back-link {

	margin: 0 0 18px;

	font-size: 1em;
	line-height: math.div(18, 16) * 1em;

	@media screen and (min-width: 768px) {
		margin: 0 0 30px;
	}

	a {
		display: inline-block;
		font-weight: bold;
		color: $purple;
		text-decoration: none;
		cursor: pointer;
	}

}

.cms-article__category-labels {

	font-size: 1em;
	line-height: math.div(18, 16) * 1em;

	margin: 0 0 math.div(8, 16)*1em;

	@media screen and (min-width: 768px) {
		margin: 0 0 math.div(12, 16)*1em;
	}
}

.cms-article__category-label {
	display: inline-block;
	color: $purple-secondary;
	font-weight: bold;
	text-transform: uppercase;

	&:not(:last-child) {
		margin-right: 2em;
	}
}

@mixin spotlightHeadingStyle() {
	font-size: 1em;
	line-height: math.div(22, 16)*1em;
	font-weight: bold;
	border-top: #eaeaea 2px solid;
	padding-top: math.div(36, 16)*1em;
	margin: 1em 0 0;

	@media screen and (min-width: 768px) {
		font-size: math.div(20, 16)*1em;
		line-height: 26*0.05*1em;
	}

}

.cms-article .spotlight-overview,
.cms-article spotlight-products,
.cms-article__related-container {

	h1,
	h3 {
		@include spotlightHeadingStyle();
	}

	.overview-grid {
		padding-top: math.div(22, 16)*1em;
	}

	@media screen and (min-width: 768px) {
		padding-left: calc(1 / 11 * (100% - 20px) + 10px);
	}
}

.cms-article .home-spotlight-overview {

	margin-top: 42px;

	@media screen and (min-width: 768px) {
		margin-top: 72px;
	}

	h1,
	h3 {
		@include spotlightHeadingStyle();
	}
}

.cms-article .spotlight-overview {
	padding-left: 0;
	padding-right: 0;

	@media screen and (min-width: 768px) {
		max-width: 100%;
	}

}

.js .js-overview-grid {
	@media screen and (min-width: 768px) {
		min-height: 300px;
	}
}
