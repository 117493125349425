@use "sass:math";
@use '../settings' as *;

.cms-submenu {
	padding: 0;
	margin: 50px 0;

	.custom-select {
		display: none;
	}

	h2 {
		font-size: math.div(20, 16)*1em;
		line-height: 32*0.05*1em;
		margin: 0 0 8px;
	}

	@media screen and (max-width: 767px) {
		background: #f7f7f7;
		$submenuoffset: 17px;
		padding: 14px $submenuoffset;
		margin: 0 $submenuoffset*-1;

		h2 {
			.no-js & {
				display: none;
			}
		}

		ul {
			display: none;
			.no-js &:last-child {
				display: block;
			}
		}

		.custom-select {
			background: #ffffff;
			display: block;
		}

	}

	ul, li {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	ul ul li {
		position: relative;
		margin-left: 15px;
		padding-left: 15px;

		&:before {
			position: absolute;
			left: 0;
			content: "- ";
		}
	}

	li {
		font-size: 1em;
		line-height: math.div(24, 16)*1em;
		padding: 4px 0;
		color: $purple;

		&.active {
			font-weight: bold;
			> a {
				text-decoration: none;
			}
			li {
				font-weight: normal;
				&.active {
					font-weight: bold;
				}
			}
		}
	}

	a {
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}
