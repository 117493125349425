@use '../settings' as *;

.fullpage-slideshow {

	background: #eee;
	transition: color .15s ease-in-out;

	&:not(.owl-loaded) {
		display: block;

		article:not(:first-child) {
			display: none;
		}
	}

	&,
	.fullpage-slideshow__item {
		width: 100%;
		height: 90vh;

		@media screen and (min-height: 480px) {
			height: auto;
			max-height: 100%;
		}
	}

	.fullpage-slideshow__item {
		background: no-repeat center center #eee;
		background-size: cover;
		color: #fff;
		position: relative;

		picture {
			display: block;

			img {
				display: block;
				width: 100%;
			}
		}

		h1 {
			font-size: 46px;
			line-height: 1em;
			margin: 8px 0;

			&:first-child {
				margin-top: 24px + 8px;
			}
		}

		h2 {
			font-size: 24px;
			line-height: 1em;
			text-transform: uppercase;
			margin: 0;
			font-weight: normal;

			&:not(:first-child) {
				margin-bottom: 6px;
			}
		}

		p {
			font-size: 1em;
			margin: 0;
		}

		ul, ol {
			padding-left: 1em;
			margin-top: 0;
			margin-bottom: 1.25em;
		}

		a:not(.button) {
			text-decoration: underline;
		}

		.button {
			margin-top: 20px;

			&--custom-outline {
				color: inherit;
				border-color: inherit;
				background-color: transparent;
				box-shadow: none;
				transition: all 0.3ms ease-in-out;

				&:hover {
					color: inherit;
					border-color: inherit;
					background-color: transparent;
					box-shadow: none;
					opacity: 0.7;
				}
			}
		}

		&__inner {
			position: relative;
			max-height: 75vh;

			&-content {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: $row-width;
				max-width: 100%;
				padding: 0 22px;

				@media screen and (max-width: 812px) and (orientation: landscape) {
					top: 30%;
				}
			}
		}

		&--text-shadow {
			text-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
		}
	}
}

.slideshow-scrolllink {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	padding: 20px;
	color: inherit;
}
