@use "sass:math";
@use "../_settings.scss" as *;

$fontsize-body: 1em; //16px
$fontsize-h1: (math.div(28, 16) * 1em);
$fontsize-h2: (math.div(20, 16) * 1em);
$fontsize-h3: (math.div(20, 16) * 1em);
$fontsize-h4: (math.div(16, 16) * 1em);

html {
	font-family: $helvetica;
	font-size: 1em; // 16px;
	line-height: math.div(22, 16);
	color: $grey;
}

h1 {
	font-size: $fontsize-h1;
	font-weight: bold;
	margin: 0 0 0;

	.body-content &,
	.login-block &,
	&.blog-overview__header,
	.text-banner__title {

		margin-bottom: .5rem;

		@media screen and (min-width: 860px) {
			margin-bottom: 1rem;
		}
	}

	.spotlight-overview & {
		margin-bottom: .5rem;

		@media screen and (min-width: 860px) {
			margin-bottom: math.div(22, 16)*1rem;
		}
	}

}

h2 {
	font-size: $fontsize-h2;
	font-weight: bold;
	margin: 0.25em 0 0.25em;
}

h3 {
	font-size: $fontsize-h3;
	font-weight: 300;
	margin: 0 0 0;
}

h4 {
	font-size: $fontsize-h4;
	font-weight: bold;
	margin: 0 0 0;
}

a {
	color: $purple;
	text-decoration: none;
}

p {
	margin: 0 0 1.25em;
}
