@use "sass:math";
@use '../settings' as *;

.main-content.col-9 {
	& > .overview-bar {
		order: 1;
		flex: 0 0 auto;
		align-self: auto;
		display: block;

		products-scanner {
			display: none;
		}
	}

	@media screen and (max-width: 1023px) {
		& > * {
			order: 5;
			flex: 0 0 auto;
			align-self: auto;
		}

		& > .overview-title {
			order: 2;
			flex: 0 0 auto;
			align-self: auto;
			text-align: center;
		}

		& > .overview-bar {
			order: 1;
			flex: 0 0 auto;
			align-self: auto;
			display: flex;
			align-items: center;

			.overview-filter-toggle {
				flex: 0 1 auto;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}

			products-sort {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			products-scanner {
				flex: 0 1 auto;
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}

		& > .overview-list,
		& > .overview-grid {
			order: 3;
			flex: 1 0 auto;
			align-self: auto;
		}
	}
}

@supports (display: grid) {
	.overview-grid {
		display: -ms-grid;
		display: grid;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		grid-column-gap: 8px;
		grid-row-gap: 8px;

		@media screen and (min-width: 768px) {
			-ms-grid-columns: 1fr 1fr 1fr;
			grid-template-columns: 1fr 1fr 1fr;
			margin: 20px 0 20px;
		}

		&.overview-grid--cms {
			margin: 16px 0 40px;

			@media screen and (min-width: 767px) {
				margin-bottom: 20px;
				padding-bottom: 0;
				margin-left: 0;
				margin-right: 0;
			}

			@media screen and (min-width: 1120px) {
				-ms-grid-columns: 1fr 1fr 1fr 1fr;
				grid-template-columns: 1fr 1fr 1fr 1fr;
				padding-bottom: 0;
			}
		}

		&.overview-grid--planthunter {
			@media screen and (min-width: 1120px) {
				-ms-grid-columns: 1fr 1fr 1fr 1fr;
				grid-template-columns: 1fr 1fr 1fr 1fr;
				overflow: auto;
				padding-bottom: 0;
			}
		}
	}

	.cms-article--blog {
		.overview-grid--cms {
			margin: 16px -17px 40px;

			@media screen and (min-width: 768px) {
				-ms-grid-columns: 1fr 1fr;
				grid-template-columns: 1fr 1fr;
				margin: 40px 0 20px;
			}

			@media screen and (min-width: 1120px) {
				-ms-grid-columns: 1fr 1fr 1fr;
				grid-template-columns: 1fr 1fr 1fr;
				overflow: auto;
				padding-bottom: 0;
			}
		}
	}

	.cms-article {
		.overview-grid--cms {
			@media screen and (min-width: 1120px) {
				-ms-grid-columns: 1fr 1fr 1fr;
				grid-template-columns: 1fr 1fr 1fr;
				overflow: auto;
				padding-bottom: 0;
			}
		}
	}
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
	.overview-grid {

		@media screen and (min-width: 768px) {
			padding: 40px 0;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-content: flex-start;
			align-items: stretch;
			min-width: 100%;
			margin: 0;
		}

		// 2 products on a row
		.overview-product:nth-child(odd),
		.loading-overlay ~ .overview-product:last-child:nth-child(even) {
			margin-right: 0;
		}

		.overview-product:nth-child(even),
		.loading-overlay ~ .overview-product:last-child:nth-child(odd) {
			margin-right: 0;
		}

		// 3 products on a row
		@media screen and (min-width: 768px) {
			.overview-product:last-child:nth-child(3n - 1),
			.loading-overlay ~ .overview-product:last-child:nth-child(3n) {
				margin-right: 305px;
			}

			.overview-product:last-child:nth-child(3n - 2),
			.loading-overlay ~ .overview-product:last-child:nth-child(3n - 1) {
				margin-right: 0;
			}

			.loading-overlay ~ .overview-product:last-child:nth-child(3n - 2) {
				margin-right: 10px;
			}
		}

		// 4 products on a row
		@media screen and (min-width: 1120px) {
			.overview-product:last-child:nth-child(3n - 1),
			.loading-overlay ~ .overview-product:last-child:nth-child(3n) {
				margin-right: 0;
			}

			.overview-product:last-child:nth-child(3n - 2),
			.loading-overlay ~ .overview-product:last-child:nth-child(3n - 1) {
				margin-right: 0;
			}

			.loading-overlay ~ .overview-product:last-child:nth-child(3n - 2) {
				margin-right: 0;
			}
		}

		&.overview-grid--cms {
			margin: 16px -17px 40px;

			@media screen and (min-width: 767px) {
				margin-bottom: 20px;
				padding-bottom: 0;
				margin-left: 0;
				margin-right: 0;
			}

			@media screen and (min-width: 1120px) {
				flex-wrap: wrap;
				padding-bottom: 0;
			}

		}
	}

	.cms-article--blog {
		.overview-grid--cms {
			margin: 16px -17px 40px;
		}
	}
}

.overview-grid.overview-grid--cms {
	padding-top: 0;
}

.overview-grid {
	.overview-product_labels {
		margin: 4px 0 0;

		@media screen and (min-width: 768px) {
			margin: 0;
			position: absolute;
			z-index: 2;
			top: -4px;
			right: -4px;
			line-height: 1em;
			width: 44px;
			text-align: right;
		}
	}

	.product-label__icon {
		margin: 4px 4px 0 0;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
		background: #ffffff;

		@media screen and (min-width: 768px) {
			display: inline-block;
			margin: 0 0 4px;
		}
	}
}

.overview-product {

	.discount-tag {
		display: inline-block;
		vertical-align: middle;
		color: #ffffff;
		background: $green;
		font-weight: bold;
		font-size: 13px;
		line-height: 19px;
		padding: 0 4px 0 6px;
		text-align: center;
		margin: 0 0 0 4px;
		border-radius: 4px;
	}
}

@media screen and (min-width: 768px) {
	// Homepage spotlight products
	.home-spotlight-overview {
		.spotlight-overview {
			.overview-product {
				@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
					width: calc(1 / 4 * 100% - (1 - 1 / 4) * 8px);
				}
			}
		}
	}

	.cms-article--blog {
		.home-spotlight-overview {
			.spotlight-overview {
				.overview-product {
					@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
						width: calc(1 / 3 * 100% - (1 - 1 / 3) * 8px);
					}
				}
			}
		}
	}

	:root {
		--amount-of-grid-columns: 3;
	}

	.overview-grid {
		.overview-product {
			border: 2px solid #eaeaea;
			border-radius: 4px;

			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
				width: calc(1 / var(--amount-of-grid-columns) * 100% - (1 - 1 / var(--amount-of-grid-columns)) * 8px);
				order: 0;
				flex: 0 0 auto;
				align-self: auto;
				margin: 0 0 15px;
				position: relative;
				display: inline-flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: flex-start;
				align-content: stretch;
				align-items: stretch;
			}

			a {
				cursor: pointer;
			}
		}

		&--planthunter {
			.overview-product {
				@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
					width: calc(1 / 4 * 100% - (1 - 1 / 4) * 0px);
				}
			}
		}
	}

	.overview-product_image {
		display: block;
		margin: 0;
		border-radius: 2px 2px 0 0;
		overflow: hidden;
		height: 182px;
		line-height: 182px;
		max-width: 100%;
		text-align: center;

		img {
			width: auto;
			height: auto;
			max-height: 100%;
			max-width: 100%;
			vertical-align: middle;
			font-style: italic;

			&:before {
				display: block;
				padding: 1em 2em;
				background: #f2f2f2;
				color: rgba(70, 29, 83, 0.5);
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				max-width: 100%;
			}

			@supports (object-fit: contain) {
				object-fit: contain;
				object-position: 50% 0;
				width: 100%;
				height: 182px;
				vertical-align: top;
			}
		}
	}

	.overview-product_title {
		font-weight: normal !important;
		font-size: 1em !important;
		color: $purple;
		flex-grow: 1;
		margin: 0;
		padding: 0;

		a {
			line-height: normal;
			&:not(:only-child) {
				padding-bottom: 0;
			}
		}
	}

	.overview-product_subtitle {
		margin: 0;
		font-weight: normal;
		font-size: math.div(14, 16)*1em;
		color: #333333;
	}

	.overview-product_info {
		padding: 0 14px;

		&:after {
			content: '';
			display: table;
			clear: both;
		}
	}

	.overview-product_size {
		font-size: (math.div(14, 16) * 1em);
		color: #919191;
		float: left;
		line-height: 28px;

		svg {
			margin: 0 0 0 0;
			vertical-align: baseline;

			& ~ svg {
				margin-left: 10px;
			}
		}

		&:last-child {
			margin-bottom: 12px;
		}
	}

	.overview-product_price {
		float: right;

		strong {
			font-size: (math.div(20, 16) * 1em);
		}

		&--from {
			padding-right: 5px;
			font-size: (math.div(14, 16) * 1em);
		}
	}

	.overview-product_add {
		padding: 10px 14px 0;

		input[type="number"] {
			width: 3.5em;
			padding: 0;
			text-align: center;
		}

		.custom-select {
			width: calc(100% - 4em - 2px);
			margin-left: 5px;
		}

		&.overview-product_not-available {
			margin: 40px 0 10px;
		}

		&.more-information {
			min-height: 53px;

			strong {
				line-height: 43px;
			}
		}
	}

	.overview-product_button {
		padding: 14px;

		.button {
			display: block;
			width: 100%;

			.icon-truck {
				margin-left: 14px;
			}

			.icon-spinner {
				margin-left: 14px;
			}
		}
	}
}

.icon-spinner {
	transform-origin: 50% 50%;
	animation: spin 1s infinite ease-in-out;
}

@keyframes spin {
	0% {
		transform: rotate(0turn);
	}
	100% {
		transform: rotate(1turn);
	}
}

@media screen and (min-width: 768px) {
	.overview-list {

		margin-top: 26px;

		.overview-product {
			display: block;
			overflow: hidden;
			padding: 26px 0;
			margin: 0 0;
			border-top: 2px solid #eaeaea;

			&:after {
				content: '';
				display: table;
				clear: both;
			}

			a {
				cursor: pointer;
			}
		}

		.overview-product_image {
			height: 137px;
			text-align: center;

			img {
				width: auto;
				height: 100%;
				max-width: 100%;
				max-height: 137px;
				vertical-align: top;

				@media screen and (max-width: 1025px) {
					max-width: 206px;
				}
			}

		}

		.overview-product_info {
			padding: 0 0 0 20px;

			@media screen and (max-width: 1025px) {
				width: calc(100% - 33%);
				max-width: 700px;
				padding-right: 0;
			}
		}

		.overview-product_title {
			font-weight: normal;
			font-size: 1em;
			line-height: (math.div(22, 16) * 1em);
			color: #333333;
			margin: 0 0 0.5em;
			padding-right: 10px;

			@media screen and (max-width: 520px) {
				font-size: (math.div(13, 16) * 1em);
				line-height: (math.div(22, 13) * 1em);
			}

			a {
				color: #333333;
				font-size: inherit;
				text-decoration: none;
				padding: 0;
			}

			.overview-product_subtitle {
				font-size: math.div(14, 16)*1em;
				line-height: normal;
				color: #919191;
				margin-top: .45em;
				padding: 0;

				@media screen and (max-width: 520px) {
					font-size: (math.div(13, 16) * 1em);
				}
			}
		}

		.overview-product_size {
			display: block;
			width: 100%;
			color: #919191;
			font-size: (math.div(13, 16) * 1em);
			line-height: (math.div(22, 13) * 1em);
			margin: 0 0 (math.div(8, 13) * 1em);

			svg.icon-potheight,
			svg.icon-potsize {
				color: #9a9a9a;
				fill: #9a9a9a;
			}
		}

		.overview-product_message {
			font-weight: bold;
			font-size: 13px;
			line-height: (math.div(22, 13) * 1em);
			margin: 0 0 (math.div(8, 13) * 1em);
			color: #000000;
			text-align: left;
		}

		.overview-product_add {
			float: right;
			max-width: 400px;
			padding: 0;

			@media screen and (max-width: 1025px) {
				display: none;
			}
		}

		.overview-history-product_not-available {
			margin: 0 0 10px;

			&strong {
				width: 100%;
			}

			&.more-information {
				width: 100%;
				text-align: center;
				font-weight: bold;
			}
		}
	}
}

.original-price {
	text-decoration: line-through;
	color: #919191;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	@media screen and (min-width: 768px) {
		.overview-product_price & {
			text-align: right;
		}
	}
}

@media screen and (max-width: 767px) {
	.overview-product {
		display: block;
		padding: 16px 17px;
		margin: 0 -17px;
		border-top: 2px solid #eaeaea;

		&:last-child {
			border-bottom: 2px solid #eaeaea;
		}

		&:after {
			content: '';
			display: table;
			clear: both;
		}
	}

	.overview-product_image {
		float: left;
		width: 24%;
		max-height: 106px;
		text-align: center;
		padding-right: $gutter;

		@media screen and (max-width: 540px) {
			width: 120px;
			max-height: 80px;
		}

		img {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 106px;
			vertical-align: top;

			@media screen and (max-width: 540px) {
				max-height: 80px;
				max-width: 100px;
			}
		}

	}

	.cms-article .overview-product_title,
	.overview-product_title {
		font-weight: normal;
		font-size: 1em;
		line-height: (math.div(22, 16) * 1em);
		color: #333333;
		margin-top: 0;
		margin-bottom: 0.5em;
		padding-right: 10px;

		.overview-product_info & {
			margin-left: 0;
		}

		@media screen and (max-width: 520px) {
			font-size: (math.div(13, 16) * 1em);
			line-height: (math.div(22, 13) * 1em);
		}

		a {
			font-size: inherit;
			text-decoration: none;
		}

		.overview-product_subtitle {
			color: #919191;
			font-size: math.div(14, 16)*1rem;
		}
	}

	.overview-product_size {
		color: #919191;
		font-size: (math.div(13, 16) * 1em);
		line-height: (math.div(22, 13) * 1em);
		margin: 0 0 (math.div(8, 13) * 1em);

		svg.icon-potheight,
		svg.icon-potsize {
			color: #9a9a9a;
			fill: #9a9a9a;
		}
	}

	.overview-product_message {
		font-weight: bold;
		font-size: 13px;
		line-height: (math.div(22, 13) * 1em);
		margin: 0 0 (math.div(8, 13) * 1em);
		color: #000000;
	}

	.overview-product {
		form,
		.overview-product_add,
		.overview-product_button {
			display: none;
		}
	}

}

@media screen and (max-width: 540px) {
	.overview-product {
		padding-top: 16px;
		padding-bottom: 16px;
	}

	.overview-product_info {
		margin-left: 120px;
	}
}

.product-buy-option-bar {
	margin: 0;

	&:after {
		content: '';
		display: table;
		clear: both;
	}

	history-buy-unit:not(:first-child) &, buy-unit:not(:first-child) &,
	& + & {
		margin-top: 8px;
	}

	&_price {
		min-width: 85px;
		max-width: 90px;
		overflow: visible;
		font-size: (math.div(13, 16) * 1em);
		line-height: (math.div(34, 13) * 1em);
		margin: 0;
		font-weight: bold;

		.product-buy-option-bar--disabled & {
			color: #919191;
		}

		&.product-buy-option-bar_price--discount {
			line-height: (math.div(17, 13) * 1em);
		}

		.original-price,
		s {
			text-decoration: line-through;
			color: #919191;
			white-space: nowrap;
			font-weight: normal;
		}
	}

	&_input {
		min-width: 110px;
		font-size: 0;
		text-align: center;
		padding-right: 10px;
		float: left;

		.quantity-input_placeholder {
			font-size: 13px;
			line-height: (math.div(34, 13) * 1em);
			display: block;
		}

		input,
		.quantity-input_input {
			padding: 0;
			text-align: center;
			font-size: 13px;
			width: (math.div(32, 13) * 1em);
			height: (math.div(34, 13) * 1em);
			border: 2px solid #cccccc;
			display: inline-block;
			vertical-align: middle;
			border-radius: 0;
			-webkit-appearance: none;

			// Hide number input arrows
			-moz-appearance: textfield;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
			}
		}

		button,
		.quantity-input_minus,
		.quantity-input_plus {
			font-size: 13px;
			width: (math.div(24, 13) * 1em);
			height: (math.div(34, 13) * 1em);
			padding: 0;
			text-align: center;
			border: 2px solid #cccccc;
			background: #eaeaea;
			color: #666666;
			cursor: pointer;
			vertical-align: middle;
			display: inline-block;

			&[disabled] {
				cursor: default;

				[class*="icon-"] {
					opacity: 0.5;
				}
			}

			&:active {
				background-color: #cccccc;
				[class*="icon-"] {
					position: relative;
					top: 1px;
				}
			}
		}

		.quantity-input_minus {
			border-right: 0;
			border-radius: 4px 0 0 4px;
		}

		.quantity-input_plus {
			border-left: 0;
			border-radius: 0 4px 4px 0;
		}

	}

	&_button {
		.button {
			width: 100%;
			font-weight: normal;
			min-width: 200px;

			@media screen and (max-width: 375px) {
				min-width: 0;
			}

			strong {
				font-weight: bold;
			}

			.icon-truck {
				margin-top: 2px;
			}

			.icon-spinner {
				margin-top: 2px;
				margin-right: 6px;
			}
		}
	}
}

.loading-overlay {
	position: relative;
	opacity: 0;
	visibility: hidden;
	transition: visibility 0s 0.4s, opacity 0.4s 0s ease-in-out;
	min-width: 40px;
	min-height: 40px;

	&:before {
		content: '';
		position: fixed;
		top: 50%;
		left: 50%;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		border: 6px solid rgba(204, 204, 204, 0.51);
		border-top-color: $purple;
		animation: spin 1s infinite linear;
		animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	}
}

.loading-overlay--fixed {
	position: fixed;
	z-index: 888888 !important;
}

.loading-overlay.loading-overlay--datatable {
	position: relative;
	z-index: 99;
	width: 100%;
	height: 200px;
	margin-bottom: -200px;
	top: 20px;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.65);

	#component-products.loading & {
		visibility: visible;
		opacity: 1;
		transition: visibility 0.01s 0s, opacity 0.01s 0s ease-in-out;
	}

	#component-products:not(.loading) & {
		visibility: hidden;
		opacity: 0;
		background: none;
		transition: visibility 0s 0.4s, opacity 0.4s 0s ease-in-out;
	}

	&:before {
		position: absolute;
		top: 80px;
		left: 50%;
		margin-left: -20px;
	}

	@media screen and (max-width: 767px) {
		top: 10px;

		&:before {
			top: 40px;
		}
	}
}

.overview-list,
.overview-grid {
	position: relative;
}

.loading-overlay {
	position: absolute;
	z-index: 99;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.65);

	.loading & {
		visibility: visible;
		opacity: 1;
		transition: visiblity 0s 0s, opacity 0.6s 0s ease-in-out;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes loading-spinner {
	0%, 100% {
		transform: translate3d(0, -50%, 0) scale(0.1);
	}
	25% {
		transform: translate3d(50%, 0, 0) scale(0.45);
	}
	50% {
		transform: translate3d(0, 50%, 0) scale(1);
	}
	75% {
		transform: translate3d(-50%, 0, 0) scale(0.5);
	}
}

@keyframes sk-rotate {
	100% {
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg)
	}
}

@keyframes sk-bounce {
	0%, 100% {
		transform: scale(0.0);
		-webkit-transform: scale(0.0);
	}
	50% {
		transform: scale(1.0);
		-webkit-transform: scale(1.0);
	}
}

@media screen and (min-width: 768px) {
	$deviationSEK: 36px;

	.overview-list[data-valuta="SEK"] {

		.overview-product_info {
			width: calc(100% - 24% - 350px - #{$deviationSEK});
		}

		.overview-product_add {
			max-width: 400px;
		}

		.product-buy-option-bar_price {
			min-width: 62px + $deviationSEK;
			max-width: 62px + $deviationSEK;
		}
	}
}

.price-unavailable {
	@media screen and (min-width: 768px) {
		display: none;
	}
}

.product-detail {
	.product-buy-option-bar_price {
		min-width: 85px;
		max-width: 98px;
	}

	.product-buy-option-bar_input {
		min-width: 110px;

		@media screen and (max-width: 320px) {
			min-width: 90px;

			&_no-controls {
				min-width: 30px;
			}
		}
	}

	.product-buy-option-bar_button {
		.button {
			min-width: 110px;
		}
	}

	.overview-product_add {
		padding: 0;
	}

	.overview-product__add_placeholder {
		.overview-product_add {
			padding: 10px 14px 0;

			.custom-select {
				width: calc(100% - 4em - 6px);
				margin-left: 9px;
			}
		}
	}

	a:not(.button) {
		text-decoration: underline;
	}

	&_related-products, &_product-order-history {
		margin: 34px 0 0;
		padding: 34px 0 0;
		border-top: 2px solid #eaeaea;
	}
}

// Language specific styling changes

.overview-grid .overview-product_button .button {
	padding-left: 2px;
	padding-right: 2px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: clip;

	& > strong:only-of-type {
		display: inline-block;
		vertical-align: middle;
		max-width: calc(100% - 30px);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	html[lang="nl"] & {
		.icon-shoppingcart {
			width: math.div(20, 16)*1em;
		}
	}
}

.product-buy-option-bar_button .button {
	strong.product-buy-option-bar_confirmation {
		display: inline-block;
		vertical-align: middle;
		max-width: calc(100% - 30px);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

// Styling used to prevent jumping of the page while lazy-loading

.overview-product {

	@supports (display: grid) {
		height: 100%;
	}

	&--grid {
		display: block;

		@media screen and (min-width: 768px) {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			align-content: stretch;
			height: 100%;
		}
	}

	.overview-list & {
		min-height: 191px;
	}

	.overview-grid & {
		min-height: 354px;

		.loggedin & {
			@media screen and (min-width: 768px) {
				min-height: 434px
			}
		}

		@media screen and (max-width: 767px) {
			min-height: 160px;
		}

		@media screen and (max-width: 540px) {
			min-height: 134px;
		}
	}

	&__add_placeholder {
		min-height: 72px;

		.loggedin & {
			min-height: 126px;
		}

		@media screen and (max-width: 767px) {
			display: none;
		}
	}

	&.overview-product-stock {

		.overview-list & {
			padding: 16px 0;
		}

		.overview-grid & {
			min-height: 484px;

			@media screen and (max-width: 767px) {
				min-height: auto;
			}
		}
	}
}
