// Custom ng-select styling
@use "sass:math";
@use 'sass:color';
@use '../settings' as *;

.custom-ng-select {
	display: inline-flex !important;
	width: 100%;

	&.ng-select-searchable {
		.ng-input {
			display: inline-block !important;
		}
	}

	.ng-select-container {
		height: (math.div(44, 16) * 1em) !important;
		border-radius: 4px;
		background: #fff;
		border: 2px solid $grey-secondary;
		display: inline-flex !important;
		max-width: 100%;

		padding: 0 (math.div(13, 16) * 1em);

		font-family: $helvetica;

		width: 100%;
		margin: 0;
		outline: none;

		text-overflow: ellipsis;

		align-items: center;

		/* Prefixed box-sizing rules necessary for older browsers */
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;

		/* Font size must be 16px to prevent iOS page zoom on focus */
		font-size: 1em;
		color: #333;

		.ng-value-container {
			height: 100%;
			align-items: center;
			align-self: center;
			position: relative;
			padding-left: 0;

			.ng-input {
				display: none;
			}
		}
	}

	.ng-value {
		width: 100%;
	}

	.select-label {
		padding-right: 20px;
		display: inline-flex;
		width: 100%;

		.option-quantity {
			text-align: left;
			width: 100%;
			align-self: center;
		}

		.option-price--discount {
			.option-price {
				font-size: 12px;
				text-decoration: line-through;
				float: right;
			}

			.option-discount {
				float: right;
			}
		}

		.option-price {
			text-align: right;
			width: 100%;
		}
	}

	&.ng-select-focused {
		&:not(.ng-select-opened) > .ng-select-container {
			border-color: #461d53;
			box-shadow: none;
		}
	}

	.ng-dropdown-panel {
		border: 2px solid $grey-secondary;
		left: 0;
		width: 100%;

		.ng-dropdown-panel-items {
			width: 100%;
		}

		.ng-option-selected {
			background-color: color.scale($purple, $alpha: -70%) !important;
			color: #fff !important;

			&.ng-option-marked {
				background-color: $purple;
				color: #fff;
			}
		}

		.ng-option {
			padding: 4px 10px;
		}

		.ng-option-marked {
			background-color: $purple !important;
			color: #fff !important;
		}
	}
}
