@use "sass:math";

.homepage-search {
	position: relative;
	z-index: 1;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background-image: linear-gradient(-180deg, rgba(232, 233, 235, 0.36) 35%, rgba(255, 255, 255, 0.00) 100%);
		height: 253px;

		@media screen and (max-width: 767px) {
			display: none;
		}
	}

	form {
		display: block;
	}

	&__label {
		padding-top: 0;
	}
}

.homepage-search__inner {
	background: #fff;
	border-top: 2px solid #EAEAEA;
	padding: 14px;
	margin: -85px 0 16px;
	border-radius: 4px;

	.fullpage-slideshow + .homepage-search & {
		margin-top: -70px;

		@media screen and (min-width: 768px) {
			margin-top: -90px;
		}
	}

	@media screen and (min-width: 768px) {
		padding: 23px (math.div(1, 12)*100%);
		border: none;
		box-shadow: 0 0 4px 0 rgba(208, 208, 208, 0.50);
	}

}

.homepage-search__form {

	@media screen and (min-width: 640px) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

.homepage-search__title {
	width: 100%;
}

.homepage-search__input {
	display: block;
	width: 100%;
	margin: 0 0 20px;

	@media screen and (min-width: 640px) {
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 185px);

	}
}

.button.homepage-search__button {
	display: block;
	width: 100%;
	margin: 0 0 40px;

	@media screen and (min-width: 640px) {
		display: inline-block;
		vertical-align: top;
		max-width: 180px;
		min-width: 145px;
		width: auto;
		margin-bottom: 20px;
	}
}

.homepage-search__categories {

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		display: block;
		margin: 1em 0;
		padding: 0;
		font-size: 1rem;

		line-height: math.div(54, 16)*1em;
		font-weight: bold;
		transition: opacity 0.4s ease-out, transform 0.2s ease-out, max-height 0.2s ease-out;
		transform-origin: 0 0;
		overflow: visible;
		max-height: 3em;

		a {
			display: inline-block;
			vertical-align: middle;

			position: relative;
			padding-left: 60px;

			.category-image {
				display: block;
				line-height: normal;
				text-align: center;
				position: absolute;
				left: 0;
				top: 0;
				width: 50px;

				vertical-align: baseline;
			}
		}

		@media screen and (min-width: 768px) {
			width: 48%;
			display: inline-block;

			white-space: nowrap;
			overflow: visible;
		}

		@media screen and (min-width: 1024px) {
			width: 24%;

			&:nth-child(3n - 1) {
				margin: 1em 13%;
			}
		}

		&.homepage-search__clear-products {
			display: none;
		}

		&.homepage-search__show-all-categories {
			display: block;
			line-height: 22px;
			margin: 1em 0;
			font-weight: normal;

			a {
				padding: 0;

				.show-less {
					display: none;
				}

				.show-all {
					display: inline;
				}

			}
		}

		.icon-arrow-right {
			color: #7D6086;
			transform: rotate(0deg);
			transition: transform 0.3s ease-in-out;
			vertical-align: baseline;
			margin-left: 3px;
		}


		@media screen and (max-width: 767px) {

			max-height: none;
			line-height: math.div(42, 16)*1em;

			a {
				padding-left: 0;

				.category-image {
					position: relative;
					bottom: 0;
					display: inline-block;
					margin-right: 10px;

					img {
						max-height: 42px;
					}
				}
			}

			&.homepage-search__show-all-categories {
				margin-top: 24px;
				margin-bottom: 32px;
			}
		}
	}

	.show-all-categories .homepage-search__show-all-categories a {
		.show-less {
			display: inline;
		}

		.show-all {
			display: none;
		}
	}

	.js & ul:not(.show-all-categories) {
		@media screen and (min-width: 1024px) {
			li:nth-child(n+7):not(.homepage-search__show-all-categories) {
				display: none;
			}
		}

		@media screen and (max-width: 1023px) {
			li:nth-child(n+5):not(.homepage-search__show-all-categories) {
				display: none;
			}
		}
	}

	.js & ul.show-all-categories {

		@media screen and (min-width: 1024px) {
			li:nth-child(n+7):not(.homepage-search__show-all-categories) {
				animation: category-animate-in 0.2s ease-out;
			}
		}
		@media screen and (max-width: 1023px) {
			li:nth-child(n+5):not(.homepage-search__show-all-categories) {
				animation: category-animate-in 0.2s ease-out;
			}
		}
	}

	.show-all-categories .homepage-search__show-all-categories {
		.icon-arrow-right {
			transform: rotate(-90deg);
			transition-duration: 0.4s;
		}
	}

}

@keyframes category-animate-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
