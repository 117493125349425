@use "sass:math";
@use '../settings' as *;

a.button,
button.button,
.button {
	display: inline-block;
	vertical-align: middle;
	min-width: 120px;
	padding: 10px 20px;
	line-height: (math.div(20, 16) * 1em);
	border-radius: (math.div(4, 16) * 1em);
	background: $purple;
	border: 2px solid $purple;
	color: #ffffff;
	font-family: $helvetica;
	font-weight: bold;
	text-decoration: none;
	text-align: center;
	box-shadow: 0 2px 0 rgba(42, 18, 50, 1);
	cursor: pointer;
	transition: background .15s ease-out, border-color .15s ease-out, box-shadow .15s ease-out, transform 0.15s ease-out;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	&:focus,
	&:hover {
		background-color: $purple-secondary;
		border-color: $purple-secondary;
		box-shadow: 0 2px 0 rgba(42, 18, 50, 0.8);
	}

	&.button--product-added {
		box-shadow: 0 2px 0 rgba(42, 18, 50, 0.8);
		transform: translateY(1px);
		cursor: default;
	}

	&:active {
		transform: translateY(1px);
	}

	&.button--disabled.button--disabled,
	&[disabled] {
		background-color: #cccccc !important;
		border-color: #cccccc !important;
		box-shadow: none !important;
		cursor: default;
		transform: translateY(0px) !important;
	}

	b, strong {
		font-weight: bold;
	}
}

.button.button--block {
	display: block;
}

.button.button--secondary {
	background: #ffffff;
	color: $purple;
	border-color: $purple-secondary;
	box-shadow: none;

	&:focus,
	&:hover {
		background: #ffffff;
		color: $purple-secondary;
		box-shadow: none;
	}
}

.button.button--outline-white {
	background: rgba(0, 0, 0, 0);
	color: #ffffff;
	border-color: currentColor;
	box-shadow: none;

	&:focus,
	&:hover {
		background: rgba(0, 0, 0, .1);
		color: #ffffff;
		border-color: currentColor;
		box-shadow: none;
	}
}

.button.button--green {
	background-color: $green;
	border-color: $green;
	color: #ffffff;
	box-shadow: 0 2px 0 rgba(65, 117, 5, 1);

	&:focus,
	&:hover {
		background-color: $green-secondary;
		color: #ffffff;
		border-color: $green-secondary;
		box-shadow: 0 2px 0 rgba(65, 117, 5, 0.8);
	}
}

$grey-button: #4d4d4d !default;

.button.button--grey {
	background-color: $grey-button;
	border-color: $grey-button;
	color: #ffffff;
	box-shadow: none;

	&:focus,
	&:hover {
		background-color: $grey-button;
		color: #ffffff;
		border-color: $grey-button;
		box-shadow: none;
	}
}

.button.button--icon-right {
	[class*="icon-"] {
		float: right;
	}
}

.button.button--text-left {
	text-align: left;
}

.button--claim-success {

	button {
		display: block;
		width: 100%;
		margin-bottom: 8px;
		text-align: center;
	}
}

.button-bar {
	margin: 0 0 20px;
	text-align: left;

	& > a:not(.button),
	& > button:not(.button) {
		text-align: center;
		display: inline-block;
		vertical-align: middle;
		line-height: (math.div(22, 16) * 1em);
		margin: 0.5em 0;

		strong {
			svg {
				margin-right: 5px;
			}
		}
	}

	a:not(.button) {
		&:focus,
		&:hover {
			text-decoration: underline;
		}
	}

	& > div {
		text-align: center;
		display: inline-block;
		vertical-align: middle;
		line-height: (math.div(22, 16) * 1em);
		margin: 0.5em 0;
	}

	&--flex {
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		flex-direction: column;

		@media screen and (min-width: 768px) {
			align-items: center;
			flex-direction: row;
		}
	}
}

.button-bar--right {
	text-align: right;
}

.button-bar--password {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 1em 0;

	@media screen and (min-width: 768px) {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		margin: 20px 0 0 20px;
	}

	@media screen and (min-width: 996px) {
		margin: 20px 0 20px 170px;
	}
}

.button-bar--justify {
	text-align: justify;
	line-height: 0;

	&:after {
		content: '';
		display: inline-block;
		width: 100%;
		height: 1px;
		margin-top: -1px;
	}
}

.button-bar--topright {
	float: right;
	margin: 0 0 20px 40px;
}

.button.button--small {
	font-size: 13px;
	line-height: (math.div(18, 13) * 1em);
	padding: (math.div(5, 13) * 1em) (math.div(9, 13) * 1em);
}

.button-bar.button-bar--va-top {
	.button {
		vertical-align: top;
	}

	& > *:not(.button) {
		vertical-align: top;
		margin-top: 0;
		line-height: 1em;
	}
}

.button-bar.button-bar--va-bottom {
	.button {
		vertical-align: bottom;
	}

	& > *:not(.button) {
		vertical-align: bottom;
		margin-bottom: 0;
		line-height: 1em;
	}
}

@media screen and (max-width: 767px) {
	.button-bar.button-bar--mobile-block {
		margin: 0 0 26px 0;

		.button-bar--action-buttons {
			display: block;
		}

		.button {
			display: block;
			width: 100%;
			margin: 0;
		}

		.button + .button {
			margin-top: 10px;
		}
	}

	.button-bar--reference {
		input {
			width: 100%;
			margin-bottom: 5px;
		}

		.button {
			width: 100% !important;
		}
	}
}

button {
	svg, use {
		pointer-events: none;
	}
}

.button--text {
	display: inline;
	padding: 0;
	background: none;
	border: none;
	color: $purple;
	cursor: pointer;

	&.button--text--green {
		color: $green;
	}

	&.underline {
		text-decoration: underline;
	}
}

.button-bar sales-person-telephone {
	display: inline-block;
}
