@use "sass:math";

.brands-overview {
	max-width: 960px;
	margin: 0 auto;
}

.brands-overview-item {

	position: relative;
	margin: 40px 0;

	@media screen and (max-width: 767px) {
		margin: 20px 0;
	}

	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

.brands-overview-item__image {
	float: left;

	width: 3*0.1*100%;
	width: calc(3 / 10 * (100% - 10px) - 10px);

	@media screen and (max-width: 767px) {
		width: math.div(2, 6)*100%;
		width: calc(2 / 6 * (100% - 10px) + 10px);
	}

	a {
		display: block;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

.brands-overview-item__text-container {

	margin-left: 3.5*0.1*100%;
	margin-left: calc(3 / 10 * (100% - 10px) + 10px);

	@media screen and (max-width: 767px) {
		margin-left: math.div(2.5, 6)*100%;
		margin-left: calc(2 / 6 * (100% - 10px) + 30px);
	}
}

.brands-overview-item__intro,
.brands-overview-item__readmore {
}

.brands-overview-item__title {

	@media screen and (max-width: 767px) {
		padding-top: 11px;
		font-size: 13px;
		line-height: 18px;

		a {
			// Make entire row clickable on small screens
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
	}

}

.brands-overview-item__intro {

	@media screen and (max-width: 767px) {

		display: none;

		br {
			display: none;
		}

		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.brands-overview-item__readmore {
	@media screen and (max-width: 767px) {
		display: none;
	}
}

