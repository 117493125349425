
@use "sass:math";
@use '../settings' as *;

.main-content {
	&.mynovi {
		margin: 30px 0 0;

	}
}

.order-detail-title,
.mynovi-title {
	margin: 0;

	@media screen and (max-width: 767px) {
		font-size: math.div(24, 16)*1em;
		line-height: math.div(32, 24)*1em;
	}
}

.mynovi-intro {
	margin: 6px 0 6px;

	@media screen and (max-width: 767px) {
		margin-bottom: 26px;
	}
}

.select-checkout-method {
	@media screen and (min-width: 1025px) {
		padding-right: math.div(1, 6)*100%;
	}

	h3 {
		font-size: math.div(20, 16)*1em;
		line-height: 24*0.05*1em;
		font-weight: normal;
		display: inline-block;
		cursor: pointer;

		svg {
			color: $purple;
			margin-left: 2px;
			margin-right: 10px;
		}

		.tag {
			background: $green;
			border-radius: 4px;
			color: #fff;
			font-size: 13px;
			line-height: 22px;
			display: inline-block;
			vertical-align: bottom;
			padding: 0 10px;
			margin: 10px 10px 2px;
		}
	}

	p, ul {
		font-size: 1em;
		line-height: math.div(22, 16)*1em;
		margin: 1em 0 math.div(22, 16)*1em 35px;
		padding: 0;
	}

	li {
		list-style: none;
		margin: 0 0 10px;
		padding: 0 0 0 22px;
		position: relative;

		.icon-check {
			color: $green;
			position: absolute;
			left: 0;
			top: 6px;
		}
	}
}

.mynovi.mynovi--pending {
	@media screen and (max-width: 767px) {
		margin: 4px 0 0;
		width: 100%;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-content: stretch;
		align-items: stretch;

		& > * {
			order: 5;
			flex: 0 1 auto;
			align-self: auto;
		}

		& > *:first-child {
			order: 1;
			margin-bottom: 0;
		}

		.mynovi-title {
			order: 2;
		}

		.mynovi-intro {
			order: 3;
		}

		.button-bar--topright {
			order: 4;
		}
	}
}

.mynovi.mynovi--history {
	@media screen and (max-width: 767px) {
		margin: 4px 0 0;
		width: 100%;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-content: stretch;
		align-items: stretch;

		& > * {
			order: 5;
			flex: 0 1 auto;
			align-self: auto;
		}

		& > *:first-child {
			order: 1;
			margin-bottom: 0;
		}

		.mynovi-title {
			order: 2;
			margin: 16px 0;
		}

		.mynovi-intro {
			order: 3;
		}

		.button-bar--topright {
			order: 4;
			margin: 0 0 28px;

			.button {
				display: block;
			}
		}

		.button-bar--right {
			margin: 28px 0;

			.button {
				display: block;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.mynovi.mynovi--order-detail {
		margin: 16px 0 0;

		.button-bar--justify {
			text-align: left;

			& > a:not(.button):not(:last-child) {
				display: inline;
				text-align: left;

				&:after {
					display: block;
					content: '\A';
					margin-bottom: 16px;
				}
			}
		}

		.button-bar.button-bar--va-bottom .text-center {
			text-align: left;
			display: block;
			margin-bottom: 18px;
			margin-top: 26px;
		}
	}
}

.shoppingcart-confirmation-message {
	margin-bottom: 20px;

	&:after {
		content: '';
		display: table;
		clear: both;
	}

	h1 {
		position: relative;
		padding-left: 45px;

		.icon-check-wrapper-rounded {
			position: absolute;
			left: 0;
			top: 3px;
		}
	}

	.button {
		margin: 20px 0 40px;

		@media screen and (max-width: 560px) {
			display: block;
		}
	}

	@media screen and (min-width: 768px) {
		margin-bottom: 0;

		h1 {
			float: left;
			margin: 0 0 30px;
		}

		.button {
			float: right;
			margin: 0 0 20px 20px;
		}
	}

	.icon-check-wrapper-rounded {
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		width: 1em;
		height: 1em;
		line-height: 1em;
		font-size: 2rem;
		background: $green;
		border-radius: 0.5em;
		margin: 0 0.25em 0 0;

		.icon-check {
			width: math.div(20, 32)*1em;
			height: math.div(17, 32)*1em;
			display: inline-block;
			vertical-align: middle;
			color: #fff;
			position: relative;
			top: -0.075em;
		}
	}
}
