@use '../settings' as *;

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-green-notice {
	color: $green-notice;
}

.text-red-notice {
	color: $red-notice;
}

.cursor--force-hand {
	cursor: pointer;
}

@media (min-width: 320px) and (max-width: 767px) {
	.hidden-mobile {
		display: none !important;
	}
}

@media (min-width: 768px) and (max-width: 1024px) {
	.hidden-tablet {
		display: none !important;
	}
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
	.hidden-tablet {
		display: none !important;
	}
}

@media (min-width: 1281px) {
	.hidden-desktop {
		display: none !important;
	}
}
