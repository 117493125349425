@use "sass:math";

.inspiration-flex {

	/// Main wrapper styles
	&__wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-content: center;
		align-items: stretch;

		margin: 10px 0;
		max-width: 100%;
	}

	/// Styling for content that has gutters in the grid
	&__grid-gutter {
		.inspiration-flex__container {
			margin-left: 0;
			margin-right: 0;

			@media screen and (min-width: 1024px) {
				margin-left: 15px;
				margin-right: 15px;
			}

			&:first-of-type {
				margin-left: 0;
			}

			&:last-of-type {
				margin-right: 0;
			}
		}

		/// Grid sizes with gutter class (make sure we don't wrap divs when it's not wanted)
		.inspiration-flex {
			&__fullwidth {
				margin-left: 0;
				margin-right: 0;
			}

			&__half {
				@media screen and (max-width: 1023px) {
					margin-bottom: 30px;

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}

			&__two-third {
				flex: 1 0 100%;

				@media screen and (min-width: 1024px) {
					flex: 1 1 61%;
				}
			}

			&__one-third {
				flex: 1 0 100%;
				margin-bottom: 30px;

				&:last-of-type {
					margin-bottom: 0;
				}

				@media screen and (min-width: 1024px) {
					flex: 1 1 30%;
					margin-bottom: 0;
				}
			}

			&__one-fourth {
				flex: 0 1 49%;
				align-items: flex-start;
				margin-bottom: 7px;

				&:nth-of-type(3),
				&:last-of-type {
					margin-bottom: 0;
				}

				@media screen and (min-width: 1024px) {
					flex: 1 1 20%;
					margin-bottom: 0;
				}

				.inspiration-flex__content {
					padding: 0;
				}
			}

			&__three-fourth {
				flex: 1 0 100%;

				@media screen and (min-width: 1024px) {
					flex: 1 1 73%
				}
			}
		}
	}

	&__container {
		flex: 1 0 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-left: 0;
		margin-right: 0;
		word-break: break-word;

		&--relative {
			position: relative;

			.inspiration-flex__content {
				position: relative;
				width: 100%;

				@media screen and (min-width: 1024px) {
					height: auto !important;
				}
			}
		}

		&.inspiration-flex__no-padding {
			.inspiration-flex__content {
                padding: 0;
			}
		}

		/// Styles for absolute positioning of content (like text over an immage)
		&--absolute {
			position: relative;

			.inspiration-flex__content {
				position: relative;
				padding: 2em (math.div(.5, 6)*100%);
				bottom: 0;
				left: 0;
				text-align: left;

				@media screen and (min-width: 1024px) {
					position: absolute;
				}

				p,
				h1,
				h2,
				h3 {
					@media screen and (max-width: 1023px) {
						color: #000;
					}
				}
			}

			&.inspiration-flex__has-image {
				.inspiration-flex__content {
					padding: 0;

					@media screen and (min-width: 1024px) {
						padding: 2em;
					}
				}
			}

			/// Vertically aligned in the center on absolute positioning only
			/// since the relative position is always vertically centered already.
			&.inspiration-flex__container--center-left {
				.inspiration-flex__content {
					text-align: left;
					top: initial;
					left: initial;
					bottom: initial;

					@media screen and (min-width: 1024px) {
						top: 50%;
						left: 0;
						bottom: initial;
						transform: translateY(-50%);
					}
				}
			}

		}

		/// Position Content in the Bottom Left Corner
		&--bottom-left {
			.inspiration-flex__content {
				text-align: left;
			}

			.inspiration-flex__content--inner {
				position: relative;
				bottom: 0;
				left: 0;
				padding: 0;
				text-align: left;

				@media screen and (min-width: 1024px) {
					position: absolute;
					padding: 20px;
				}
			}

			&.inspiration-flex__container--relative {
				.inspiration-flex__content--inner {
					position: relative;
					padding: 0;
				}
			}

			.inspiration-flex__content--half-width {
				.inspiration-flex__content--inner {
					margin-right: 0;

					@media screen and (min-width: 1024px) {
						margin-right: 50%;
					}
				}
			}

			.inspiration-flex__content--third-width {
				.inspiration-flex__content--inner {
					margin-right: 0;

					@media screen and (min-width: 1024px) {
						margin-right: 66%;
					}
				}
			}
		}

		/// Position Content Bottom Center
		&--bottom-center {
			.inspiration-flex__content {
				text-align: left;

				@media screen and (min-width: 1024px) {
					text-align: center;
				}
			}

			.inspiration-flex__content--inner {
				position: relative;
				bottom: 0;
				left: 0;
				padding: 0;
				text-align: left;

				@media screen and (min-width: 1024px) {
					position: absolute;
					padding: 20px;
					text-align: center;
				}
			}

			/// Add some specific styles for half width and 1/3th content limitations
			.inspiration-flex__content--half-width,
			.inspiration-flex__content--third-width {
				.inspiration-flex__content--inner {
					@media screen and (min-width: 1024px) {
						margin: 0 auto;
					}
				}
			}

			&.inspiration-flex__container--relative {
				.inspiration-flex__content--inner {
					position: relative;
					padding: 0;
				}
			}

			.inspiration-flex__content--half-width {
				.inspiration-flex__content--inner {
					margin-right: 0;
					margin-left: 0;

					@media screen and (min-width: 1024px) {
						margin-right: 25%;
						margin-left: 25%;
					}
				}
			}

			.inspiration-flex__content--third-width {
				.inspiration-flex__content--inner {
					margin-right: 0;
					margin-left: 0;

					@media screen and (min-width: 1024px) {
						margin-right: 33%;
						margin-left: 33%;
					}
				}
			}
		}

		///  Position Content in the Bottom Right Corner
		&--bottom-right {
			.inspiration-flex__content {
				text-align: left;

				@media screen and (min-width: 1024px) {
					text-align: right;
				}
			}

			.inspiration-flex__content--inner {
				position: relative;
				bottom: 0;
				left: 0;
				padding: 0;
				text-align: left;

				@media screen and (min-width: 1024px) {
					position: absolute;
					padding: 20px;
					text-align: right;
				}
			}

			/// Add some specific styles for half width and 1/3th content limitations
			.inspiration-flex__content--half-width,
			.inspiration-flex__content--third-width {
				.inspiration-flex__content--inner {
					@media screen and (min-width: 1024px) {
						float: right;
					}
				}
			}

			&.inspiration-flex__container--relative {
				.inspiration-flex__content--inner {
					position: relative;
					padding: 0;
				}
			}

			.inspiration-flex__content--half-width {
				.inspiration-flex__content--inner {
					margin-left: 0;

					@media screen and (min-width: 1024px) {
						margin-left: 50%;
					}
				}
			}

			.inspiration-flex__content--third-width {
				.inspiration-flex__content--inner {
					margin-left: 0;

					@media screen and (min-width: 1024px) {
						margin-left: 66%;
					}
				}
			}
		}

		&--row {
			flex-direction: row;
		}

		&--row-reverse {
			flex-direction: row-reverse;
		}

		@media screen and (min-width: 1024px) {
			flex: 1 0 20%;
			margin-left: 0;
			margin-right: 0;
			justify-content: space-evenly;
		}
	}

	&__fullwidth {
		flex: 1 0 100%;

		.inspiration-flex__content--inner {
			width: 100%;
		}

		.inspiration-flex__content {
			&--half-width {
				.inspiration-flex__content--inner {
					width: 100%;

					@media screen and (min-width: 1024px) {
						width: 50%;
					}
				}
			}

			&--third-width {
				.inspiration-flex__content--inner {
					width: 100%;

					@media screen and (min-width: 1024px) {
						width: 33%;
					}
				}
			}
		}
	}

	&__half {
		flex: 1 0 100%;
		margin-bottom: 30px;

		&:last-of-type {
			margin-bottom: 0;
		}

		@media screen and (min-width: 1024px) {
			flex: 1 0 45%;
			max-width: 50%;
			margin-bottom: 0;
		}
	}

	&__two-third {
		flex: 1 0 100%;

		@media screen and (min-width: 1024px) {
			flex: 1 0 66%;
			max-width: 66%;
		}
	}

	&__one-third {
		flex: 1 0 100%;
		margin-bottom: 30px;

		@media screen and (min-width: 1024px) {
			flex: 1 0 33%;
			max-width: 34%;
			margin-bottom: 0;
		}
	}

	&__one-fourth {
		flex: 0 1 49%;
		align-items: flex-start;
		margin-bottom: 7px;

		&:nth-of-type(3),
		&:last-of-type {
			margin-bottom: 0;
		}

		@media screen and (min-width: 1024px) {
			flex: 1 1 25%;
			max-width: 25%;
			margin-bottom: 0;
		}
	}

	&__three-fourth {
		flex: 1 1 100%;

		@media screen and (min-width: 1024px) {
			flex: 1 1 75%;
			max-width: 75%;
		}
	}

	&__image {
		margin: 0;
		align-self: flex-start;
		width: 100%;
		overflow: hidden;

		img {
			object-fit: cover;
			object-position: center center;
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 100%;
		}
	}

	&__content {
		max-height: 100%;
		overflow: auto;
		padding: 2em (math.div(.5, 6)*100%);
		height: auto;
		width: 100%;
		display: flex;

		@media screen and (min-width: 1024px) {
			height: 100%;
		}

		h1 {
			margin-bottom: 1rem;
			color: inherit;
		}

		h2 {
			margin-top: 0;
			margin-bottom: 1rem;
			color: inherit;
		}

		&--inner {
			align-self: center;

			p {
				margin: 0 0 1.25em;

				@media screen and (min-width: 1024px) {
					margin: 0;
				}
			}

			.button {
				&--custom-outline {
					color: inherit;
					border-color: inherit;
					background-color: transparent;
					box-shadow: none;
					transition: all 0.3ms ease-in-out;

					&:hover {
						color: inherit;
						border-color: inherit;
						background-color: transparent;
						box-shadow: none;
						opacity: 0.7;
					}
				}
			}
		}

		/// Allow text-shadow on content if enabled. Disable again on mobile since the text will most likely
		/// collapse on a white background in black text
		&--text-shadow {
			text-shadow: none;

			@media screen and (min-width: 1024px) {
				text-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
			}
		}

		&--full-width {
			.inspiration-flex__content--inner {
				width: 100%;
			}
		}
	}

	&__no-images {
		align-items: center !important;
	}
}
