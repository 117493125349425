@use "sass:math";
@use '../settings' as *;

$grid-columns: 12 !default;

html {
	box-sizing: border-box;

	*, *:before, *:after {
		box-sizing: inherit;
	}
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

iframe {
	max-width: 100%;
}

.row {
	width: $row-width;
	max-width: 100%;
	position: relative;
	margin: 0 auto;
	padding: 0 12px;

	@media screen and (max-width: 767px) {
		padding: 0 ($gutter * 0.5);
	}

	.row {
		padding: 0;
		margin-left: $gutter * 0.5 * -1;
		margin-right: $gutter * 0.5 * -1;

		max-width: calc(100% + 20px);

		@media screen and (max-width: 767px) {
			margin-left: $gutter-mobile * 0.5 * -1;
			margin-right: $gutter-mobile * 0.5 * -1;
			max-width: calc(100% + 14px);
		}
	}

	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

[class*="col-"] {
	position: relative;
	float: left;

	min-height: 1px;

	padding-left: $gutter * 0.5;
	padding-right: $gutter * 0.5;

	&:after {
		content: '';
		display: table;
		clear: both;
	}

	@media screen and (max-width: 767px) {
		padding-left: $gutter-mobile * 0.5;
		padding-right: $gutter-mobile * 0.5;
	}
}

@for $i from 1 through $grid-columns {
	.col-#{$i} {
		width: math.div(100%, $grid-columns) * $i;
	}
}

@media screen and (min-width: 1024px) {
	.hide-lg {
		display: none !important;
	}
}

@media screen and (max-width: 1023px) {
	@for $i from 1 through $grid-columns {
		.col-md-#{$i} {
			width: math.div(100%, $grid-columns) * $i;
		}
	}

	.hide-md {
		display: none !important;
	}

	.visible-md {
		display: block !important;
	}
}

@media screen and (max-width: 767px) {
	@for $i from 1 through $grid-columns {
		.col-sm-#{$i} {
			width: math.div(100%, $grid-columns) * $i;
		}
	}

	.hide-sm {
		display: none !important;
	}

	.visible-sm {
		display: block !important;
	}
}

.body-content {
	max-width: 100%;
}

@supports (flex-wrap: nowrap) {

	html {
		height: 100%;
	}

	noviflora-webshop,
	body {
		display: flex;
		flex-direction: column;
		min-height: 100%;

		& > .body-content {
			margin: 0 0 auto;
		}

		&.popupOpen {
			position: fixed;
			width: 100%;
			overflow-y: scroll;
		}
	}

	noviflora-webshop {
		min-height: 100vh;
	}

}

noviflora-webshop .body-content {
	min-height: calc(100vh - 130px); // 140 is height of header
}
