@use "sass:math";
@use '../settings' as *;

.cms-article.cms-article--blog {
	position: relative;
	z-index: 2;
	margin: 0;
	padding: 1em 0;
	background: #fff;

	h1 {
		color: #333;
	}

	@media screen and (min-width: 768px) {

		min-height: 380px;

		border-radius: 4px;
		padding-top: 37px;
		padding-bottom: 1px;
		padding-left: calc(1 / 11 * (100% - 20px) + 10px);
		padding-right: calc(1 / 11 * (100% - 20px) + 10px);

		.cms-header--blog ~ .row & {

			margin-top: -100px;

			// Add shadow
			&:before,
			&:after {
				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				right: 0;
			}

			&:before {
				z-index: -2;
				background: #FFFFFF;
				box-shadow: 0 1px 4px 0 rgba(208, 208, 208, 0.50);
				border-radius: 4px;
				height: 340px;
			}

			&:after {
				top: 396px - 124px;
				height: 124px;
				left: -10px;
				right: -10px;
				background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 3%, rgba(255, 255, 255, 1) 37%, rgba(255, 255, 255, 1) 100%);
			}
		}
	}

}

.cms-section {
	&--blog {
		margin: 50px 0;
	}
}

.cms-article__back-link {

	margin: 0 0 18px;

	font-size: 1em;
	line-height: math.div(18, 16) * 1em;

	@media screen and (min-width: 768px) {
		margin: 0 0 30px;
	}

	a {
		display: inline-block;
		font-weight: bold;
		color: $purple;
		text-decoration: none;
	}

}

.cms-article__category-labels {

	font-size: 1em;
	line-height: math.div(18, 16) * 1em;

	margin: 0 0 math.div(8, 16)*1em;

	@media screen and (min-width: 768px) {
		margin: 0 0 math.div(12, 16)*1em;
	}
}

.cms-article__category-label {
	display: inline-block;
	color: $purple-secondary;
	font-weight: bold;
	text-transform: uppercase;

	&:not(:last-child) {
		margin-right: 2em;
	}
}

.cms-article .spotlight-overview,
.cms-article__related-container {

	h1,
	h3 {
		font-size: 1em;
		line-height: math.div(22, 16)*1em;
		font-weight: bold;
		border-top: #EAEAEA 2px solid;
		padding-top: math.div(36, 16)*1em;
		margin: 1em 0 0;

		@media screen and (min-width: 768px) {

			font-size: math.div(20, 16)*1em;
			line-height: 26*0.05*1em;
		}

	}

	.overview-grid {
		padding-top: math.div(22, 16)*1em;
	}

	@media screen and (min-width: 768px) {
		padding-left: calc(1 / 11 * (100% - 20px) + 10px);
		//padding-right: calc(1 / 11 * (100% - 20px) + 10px);
	}
}

.cms-article .home-spotlight-overview {

	margin-top: 42px;

	@media screen and (min-width: 768px) {
		margin-top: 30px;
	}
}

.cms-article .spotlight-overview {
	padding-left: 0;
	padding-right: 0;

	@media screen and (min-width: 768px) {
		max-width: 100%;
	}

}

.js .js-overview-grid {
	@media screen and (min-width: 768px) {
		min-height: 300px;
	}
}
