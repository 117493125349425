@use "sass:math";
@use '../settings' as *;

.cms-header {
	background: #eeeeee no-repeat center center;
	background-size: cover;
	position: relative;

	&.cms-header--blog {
		height: 550px;

		position: relative;
		z-index: 1;

		&:before {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			background-image: linear-gradient(-180deg, rgba(232, 233, 235, .36) 35%, rgba(255, 255, 255, 0) 100%);
			height: 253px;
			max-height: 100%;
		}

		& + .row {
		}

		@media screen and (max-width: 767px) {
			height: 180px;
			height: auto;
		}

		picture {
			display: block;
			width: 100%;
			height: auto;
		}

		img {
			display: block;
			width: 100%;
			height: auto;

			@supports (object-fit: cover) {
				min-height: 180px;
				max-height: 50vh;
				width: 100%;
				object-position: center center;
				object-fit: cover;
			}

			@media screen and (min-width: 768px) {
				height: 550px;

				@supports (object-fit: cover) {
					object-position: 50% top;
					height: 550px;
					max-height: 90vh;
				}
			}
		}
	}
}

.cms-header__inner {

	width: 100%;
	margin: 0;
	color: $purple;
	text-align: left;

	h1 {
		color: inherit;
		margin: 0;
		display: table-cell;
		vertical-align: middle;
		height: 265px;
		padding: 40px 0;

		font-size: 46px;
		line-height: math.div(60, 46)*1em;

		@media screen and (max-width: 767px) {
			height: 100px;
			height: 24vw;
			padding: 20px 0;
			font-size: 24px;
		}

		[data-header-height="MEDIUM"] & {
			height: 420px;

			@media screen and (max-width: 767px) {
				height: 180px;
				height: 40vw;
			}
		}

		[data-header-height="LARGE"] & {
			height: 560px;

			@media screen and (max-width: 767px) {
				height: 240px;
				height: 54vw;
			}
		}

	}

}

.cms-header--home {

	background: url(/template/assets/images/fallback-header-home.jpg) center center no-repeat #eee;
	background-size: cover;

	.cms-header__inner {
		max-width: 80%;
	}

	@media screen and (min-width: 768px) {

		height: 500px;
		min-height: 500px;

		.cms-header__inner {
			max-width: 75%;
		}

		h1 {
			height: 385px;
		}
	}
}

$purple: #461d53 !default;
$green: #69b938 !default;

$cmsheaderColors: (
		"white": #ffffff,
		"black": #000000,
		"gray70": #4d4d4d,
		"gray30": #b3b3b3,
		"purple": $purple,
		"green": $green
);

@each $cmsHeaderColor, $textColor in $cmsheaderColors {
	.cms-header.cms-header--#{$cmsHeaderColor} {
		&, * {
			color: $textColor;
		}
	}
}

.cms-header.cms-header--regular {
	&, * {
		font-weight: normal;
	}
}

.cms-header.cms-header--bold {
	&, * {
		font-weight: bold;
	}
}

.cms-header.cms-header--text-center {
	.cms-header__inner {
		text-align: center;
	}
}

.cms-header.cms-header--planthunter,
.cms-header.cms-header--with-description {

	.cms-header__inner {
		height: 265px;
		margin: 0 auto;

		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-content: stretch;
		align-items: stretch;

		@media screen and (max-width: 767px) {
			height: 180px;
			height: 24vw;
		}

	}

	&[data-header-height="MEDIUM"] .cms-header__inner {
		height: 420px;
		@media screen and (max-width: 767px) {
			height: 180px;
			height: 40vw;
		}
	}

	&[data-header-height="LARGE"] .cms-header__inner {
		height: 560px;
		@media screen and (max-width: 767px) {
			height: 240px;
			height: 54vw;
		}
	}

	h1, p {
		order: 0;
		flex: 0 0 auto;
		align-self: auto;
		display: block;
	}

	h1 {
		height: auto;
		min-height: 0;
		margin: 0;
		padding: 0;

		&:last-child {
			margin-bottom: auto;
		}
	}

	p {
		margin: 0;
		font-size: 20px;
	}
}
