@use "sass:math";
@use '../settings' as *;

.blog-overview {
	margin-top: 50px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: stretch;
	align-items: stretch;
	min-width: 100%;
	height: auto;

	@media screen and (max-width: 767px) {
		margin-top: 20px;
	}

	@media screen and (min-width: 840px) {
		margin-right: -20px;
	}

	&.blog-overview--home {

		margin-top: math.div(20, 16)*1rem;

		@media screen and (min-width: 768px) {
			margin-top: math.div(6, 16)*1rem;
			margin-right: -20px;
		}
	}

	&:before {
		content: '';
		display: table;
		clear: both;
		width: 100%;
	}

}

.blog-overview-item {

	order: 0;
	flex: 0 1 auto;
	align-self: stretch;
	margin: 30px 0;
	position: relative;

	&:first-child {
		margin-top: 0;
	}

	&__image {
		a, img {
			display: block;
			width: 100%;
		}

		a {
			position: relative;
			overflow: hidden;

			&:before {
				content: '';
				display: block;
				width: 100%;
				padding: 0 0 math.div(398, 705)*100%;
				background: #EAEAEA;
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}
		}
	}

	&__category {
		color: $purple-secondary;
		text-transform: uppercase;
		font-weight: bold;
		font-size: math.div(13, 16)*1em;
		line-height: math.div(19, 13)*1em;
		margin-top: math.div(16, 13)*1em;

		a, span {
			text-decoration: none;
			color: inherit;
			display: inline-block;

			&:not(:last-child) {
				margin-right: 2em;
			}
		}
	}

	&__title {
		font-size: 1em;
		line-height: math.div(22, 16)*1em;
		color: $purple;

		a {
			color: inherit;
			text-decoration: none;
		}

	}

	&__intro {
		display: none;
	}

	&__readmore {
		margin: 0;

		.icon-arrow-right {
			margin-left: 2px;
		}

		@media screen and (max-width: 839px) {
			a {
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}

	}
}

.blog-overview-item--small-home,
.blog-overview-item--small {

	@media screen and (max-width: 839px) {
		width: 100%;

		.blog-overview-item__image {
			float: left;
			width: calc(2 / 6 * (100% + 20px) + 20px);
		}

		.blog-overview-item__text {
			margin-left: calc(2 / 6 * (100% + 20px) + 20px + 14px);
		}

		.blog-overview-item__category {
			font-size: math.div(13, 16)*1em;
			line-height: math.div(22, 13)*1em;
			margin: 0;
		}

		.blog-overview-item__title {
			font-size: math.div(13, 16)*1em;
			line-height: math.div(22, 13)*1em;
			margin: 0;
		}

		.blog-overview-item__intro {
			display: none;
		}
	}

	@media screen and (min-width: 840px) {
		width: calc((100% - 60px) / 3);
		margin-right: 20px;
	}
}

.blog-overview-item--large {
	width: 100%;
	margin: 20px 0;

	@media screen and (min-width: 840px) {

		padding-right: 20px;

		.blog-overview-item__image {
			float: left;
			width: calc(5 / 9 * (100% + 20px) - 20px);
		}

		.blog-overview-item__text {
			margin-left: calc(5 / 9 * (100% + 20px));
		}

		.blog-overview-item__category {
			margin-top: 0;
		}

		.blog-overview-item__title {

			font-size: math.div(28, 16)*1em;
			line-height: math.div(34, 28)*1em;
			margin: math.div(11, 28)*1em 0 math.div(8, 28)*1em;
		}

		.blog-overview-item__intro {
			display: block;
		}
	}
}

.blog-overview-item--large-home + .blog-overview-item--small-home {
	clear: left;
}

.blog-overview-item--large-home {
	margin-top: 0;
	margin-bottom: 2em;
	width: 100%;

	@media screen and (min-width: 768px) {
		width: calc(50% - 20px);
		margin-right: 20px;
		margin-bottom: 3em;
	}

}

.blog-overview-item--small-home {

	width: 100%;
	margin: 0 0 2em 0;

	@media screen and (min-width: 840px) {
		width: calc((100% - 80px) / 4);
		margin: 0 20px 2em 0;
	}
}
