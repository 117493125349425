@use "sass:math";

.scroll-lock {
	height: 100%;
	overflow: hidden;
	width: 100%;
	position: fixed;
}

.modal {
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;

	line-height: 99.9vh;
	text-align: center;

	perspective-origin: 50% 50%;

	@media not all and (min-resolution: .001dpcm) {
		@supports (-webkit-appearance:none) {
			perspective-origin: unset;
		}
	}
}

.modal-background {
	background: rgba(0, 0, 0, 0.4);
	z-index: 1;
	position: absolute;
	top: -50px;
	bottom: -50px;
	right: -50px;
	left: -50px;
}

.modal-container {
	position: relative;
	z-index: 10;
	background: #ffffff;
	display: inline-block;
	vertical-align: middle;
	width: 90%;
	max-height: 90%;
	overflow: auto;
	line-height: (math.div(22, 16) * 1em);
	text-align: left;
	padding: 40px;

	max-width: 760px;

	perspective: 1200px;
	backface-visibility: hidden;

	.button-bar:last-child {
		margin-bottom: 0;
	}

	@media screen and (max-width: 767px) {
		height: 100%;
		width: 100%;
		max-height: 100%;
		max-width: 100%;
		padding: 20px 14px;
		vertical-align: top;
	}

}

.modal-form {
	margin-bottom: 40px;
}

.modal--hide {
	visibility: hidden;
	transition: visibility 0s 0.4s;

	.modal-background {
		//visibility: hidden;
		opacity: 0;
		transition: opacity 0.2s 0.2s ease-in-out, visibility 0s 0.4s;
	}

	.modal-container {
		opacity: 0;
		visibility: hidden;
		transform: perspective(1200px) rotateX(90deg) scale(0.9);
		transition: opacity 0.1s 0.2s ease-in-out, transform 0.3s ease-in, visibility 0s 0.4s;

		@media not all and (min-resolution: .001dpcm) {
			@supports (-webkit-appearance:none) {
				transform: none;
			}
		}

		& > * {
			opacity: 0;
			transition: opacity 0.2s ease-out;
		}
	}
}

.modal--show {
	visibility: visible;

	.modal-background {
		visibility: visible;
		opacity: 1;
	}

	.modal-container {
		visibility: visible;
		opacity: 1;
		transform: perspective(1200px) rotateX(0deg) scale(1);

		@media not all and (min-resolution: .001dpcm) {
			@supports (-webkit-appearance:none) {
				transform: none;
			}
		}

		& > * {
			opacity: 1;
			transition: opacity 0.175s 0.1s
		}
	}

	.modal-background {
		animation: modal-animate-background 0.4s ease-out;

		@media not all and (min-resolution: .001dpcm) {
			@supports (-webkit-appearance:none) {
				animation: none;
			}
		}
	}

	.modal-container {
		animation: modal-animate-container 0.45s ease-in-out;

		@media not all and (min-resolution: .001dpcm) {
			@supports (-webkit-appearance:none) {
				animation: none;
			}
		}

		& > * {
			animation: modal-animate-container-content 0.45s ease-out;

			@media not all and (min-resolution: .001dpcm) {
				@supports (-webkit-appearance:none) {
					animation: none;
				}
			}
		}
	}

}

@keyframes modal-animate-container-content {
	0% {
		visibility: hidden;
		opacity: 0;
	}
	33% {
		visibility: visible;
		opacity: 0;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

@keyframes modal-animate-background {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	1% {
		opacity: 1;
		visibility: visible;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

@keyframes modal-animate-container {
	0% {
		opacity: 0;
		visibility: hidden;
		transform: perspective(1200px) rotateX(90deg) scale(0.9);
	}
	1% {
		visibility: visible;
		opacity: 0;
		transform: perspective(1200px) rotateX(90deg) scale(0.9);
	}
	20% {
		opacity: 1;
		transform: perspective(1200px) rotateX(70deg) scale(0.9);
	}

	50% {
		opacity: 1;
	}

	100% {
		visibility: visible;
		opacity: 1;
		transform: perspective(1200px) rotateX(0deg) scale(1);
	}
}

.modal-title {
	font-size: math.div(28, 16)*1em;
	line-height: math.div(32, 28);
	margin-bottom: 4px;
}
