@use "sass:math";
@use '../settings' as *;

.text-banner {

	background: #b3b3b3;

	border-radius: 4px;
	margin: 40px 0;

	padding: 18px 28px 22px;

	.home-spotlight-overview + section & {
		@media screen and (min-width: 768px) {
			margin-top: 60px;
		}
	}

	&:after {
		content: '';
		display: table;
		clear: both;
	}

}

.text-banner__inner {

}

.text-banner__title {

	margin-bottom: .5rem;
	font-weight: 700;

	@media screen and (min-width: 860px) {

		margin-bottom: 1rem;
	}
}

.text-banner__text {

	&:last-child p:last-child {
		margin-bottom: 0;
	}

	@media screen and (min-width: 860px) {
		width: math.div(8, 12)*100%;
		float: left;

		&:not(:last-child) {
			p {
				margin-bottom: 0;
			}
		}
	}

}

.text-banner__button {
	@media screen and (min-width: 860px) {
		//max-width: 4/12*100%;
		float: right;
		padding-left: $gutter;
	}
}
