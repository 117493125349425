@use "sass:math";
@use '../settings' as *;

$gutter: 20px !default;

.inspiration-banner-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: stretch;
	align-items: stretch;

	margin: 40px 0;
	max-width: 100%;

	&.inspiration-banner-container--25 {
		margin-left: $gutter*0.5*-1;
		margin-right: $gutter*0.5*-1;
	}
}

.inspiration-banner {
	float: left;
	clear: both;

	order: 0;
	flex: 0 0 auto;
	align-self: auto;
	max-width: 100%;

	margin: 0 0 40px;

	.inspiration-banner__content {
		margin: auto 0;

		p {

			&:last-child,
			&:first-child {
				margin-bottom: 0;
			}

			a:not(.button) {
				color: $purple;
				text-decoration: underline;
			}
		}
	}

	h1, h2, h3, h4, a {
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
	}
	h1 {
	}

	h4 {
		margin-bottom: 0;
	}

	.inspiration-banner__img {
		display: block;
		margin: 0;
		position: relative;
		z-index: 2;

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&.inspiration-banner--25 {
		width: 50%;
		flex: 0 0 auto;
		padding: 0 $gutter*0.5;
		margin-bottom: 20px;
		align-self: flex-start;

		@media screen and (min-width: 768px) {
			width: 25%;
		}

		.inspiration-banner__img {
			margin-bottom: 1em;
		}
	}

	@media screen and (max-width: 767px) {

		&.inspiration-banner--portrait,
		&.inspiration-banner--square {
			.inspiration-banner__content {
				order: 1;
				padding: 2em (math.div(.5, 6)*100%);
			}

			.inspiration-banner__img {
				margin-bottom: 1em;
			}
		}

		&.inspiration-banner--square {
			&.inspiration-banner__no-padding {
				.inspiration-banner__content {
					@media screen and (max-width: 1024px) {
						padding: 2em 0;
					}
				}
			}
		}

		&.inspiration-wrapper {
			&--row {
				flex-direction: row;
			}

			&--row-reverse {
				flex-direction: row-reverse;
			}

			&--column {
				flex-direction: column;
			}

			&--column-reverse {
				flex-direction: column-reverse;
			}
		}
	}

	@media screen and (min-width: 768px) {
		margin: 0;

		&.inspiration-banner--portrait,
		&.inspiration-banner--square {

			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-content: center;
			align-items: center;

			flex: 0 1 auto;
			width: 100%;
			max-width: 100%;
			float: none;

			.inspiration-banner__content,
			.inspiration-banner__img {
				width: 50%;
				flex: 0 0 auto;
			}

			.inspiration-banner__img {
				height: 100%;
				overflow: hidden;

				@supports (object-fit: cover) {
					img {
						object-fit: cover;
						object-position: center center;
						display: block;
						width: 100%;
						height: 100%;
						max-height: 100%;
						max-width: 100%
					}
				}
			}

			.inspiration-banner__content {
				max-height: 100%;
				overflow: auto;
				margin: 0 auto;
				padding: 2em (math.div(.5, 6)*100%);
			}

		}

		&.inspiration-banner--portrait {

			$inspirationBannerOverlap: 120px;

			&:nth-child(odd) {
				.inspiration-banner__content {
					padding-bottom: calc(#{$inspirationBannerOverlap} + 2em);
				}

				.inspiration-banner__img {
					height: calc(100% + #{$inspirationBannerOverlap});
					align-self: flex-start;
				}

				&:last-child .inspiration-banner__img {
					height: 100%;
				}
			}

			&:nth-child(even) {
				.inspiration-banner__content {
					padding-top: calc(#{$inspirationBannerOverlap} + 2em);
				}

				.inspiration-banner__img {
					margin-top: $inspirationBannerOverlap*-1;
					height: calc(100% + #{$inspirationBannerOverlap});
					align-self: flex-end;
				}
			}
		}

		&.inspiration-wrapper {
			&--row {
				flex-direction: row;
			}

			&--row-reverse {
				flex-direction: row-reverse;
			}

			&--column {
				flex-direction: column;
			}

			&--column-reverse {
				flex-direction: column-reverse;
			}
		}
	}
}
