/*
	Vendor library:
	https://github.com/csilva2810/notifier
*/

@use "sass:math";
@use '../settings' as *;

$blue: #4a9bee;
$gray-600: #868e96 ;
$green: $green-notice;
$yellow: #ffc107;
$red: $red-notice;

.notifier-container {
	z-index: 999;
	position: fixed;
	top: 4px;
	right: 4px;
	padding: 4px;
	width: 350px;
	max-width: 98%;
	font-family: "Segoe UI", "Tahoma", "Calibri", "Verdana", sans-serif;
	color: #999;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

.notifier {
	position: relative;
	width: 100%;
	min-height: 62px;
	margin-bottom: 12px;
	padding: 8px;
	background: white;
	border-left: 4px solid #95a5a6;
	border-radius: 1px;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05);

	opacity: 0;
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	-ms-transform: translateX(100%);
	transform: translateX(100%);

	-webkit-transition: all .6s cubic-bezier(0.5, -0.5, 0.3, 1.4),
	opacity .6s ease;
	-moz-transition: all .6s cubic-bezier(0.5, -0.5, 0.3, 1.4),
	opacity .6s ease;
	-ms-transition: all .6s cubic-bezier(0.5, -0.5, 0.3, 1.4),
	opacity .6s ease;
	transition: all .6s cubic-bezier(0.5, -0.5, 0.3, 1.4),
	opacity .6s ease;
}

.notifier.shown {
	opacity: 1;
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}

.notifier.shown:hover {
	opacity: 1;
}

.notifier-close {
	position: absolute;
	top: 4px;
	right: 4px;
	width: 16px;
	height: 16px;
	padding: 0;
	text-align: center;
	text-decoration: none;
	color: #aaa;
	font-weight: bold;
	font-size: 16px;
	background: transparent;
	outline: none;
	border: none;
	border-radius: 50%;
	cursor: pointer;
	opacity: .5;
	-webkit-transition: .3s ease;
	-moz-transition: .3s ease;
	-ms-transition: .3s ease;
	transition: .3s ease;
}

.notifier-close:hover,
.notifier-close:focus {
	opacity: 1;
	color: #999;
	background: #eee;
}

.notifier-img {
	float: left;
	margin-right: 8px;
	vertical-align: middle;
}

.img {
	width: 48px;
	height: 48px;
}

.notifier-title {
	margin: 0;
	padding: 0;
	margin-bottom: 4px;
	font-size: 18px;
	font-weight: normal;
}

.notifier-body {
	font-size: 13px;
}

.notifier.info {
	border-left-color: #3498db;
}

.notifier.success {
	border-left-color: #1abc9c;
}

.notifier.warning {
	border-left-color: #f1c40f;
}

.notifier.danger {
	border-left-color: #e74c3c;
}

/*
	Custom styling :
 */

.notifier-container {
	font-family: inherit;
	top: 10px;
	right: 10px;
	width: auto;
	max-width: 100%;
}

.notifier {
	border-radius: .3rem;
	border-left-color: $gray-600;
	min-height: 2em;
	max-width: 400px;
}

.notifier.info {
	border-left-color: $blue;
}

.notifier.success {
	border-left-color: $green;
}

.notifier.warning {
	border-left-color: $yellow;
}

.notifier.danger {
	border-left-color: $red;
}

.notifier-close {
	font-size: 16px;
	line-height: normal;
	width: math.div(24, 16)*1em;
	height: math.div(24, 16)*1em;
	text-align: center;
	padding-bottom: math.div(4, 16)*1em;
}

.notifier-title:empty {
	display: none;
}

.notifier-body {
	font-size: 0.9rem;
	color: #333;
	position: relative;
	padding: .5em 1.5em .5em 0;
}
