@use "sass:math";
@use '../settings' as *;

.cms-article {
	padding: 0;
	margin: 50px 0;

	@media screen and (max-width: 767px) {
		padding: 0;
		margin: 22px 0;
	}

	h1 {
		font-size: math.div(46, 16)*1em;
		line-height: math.div(54, 46)*1em;
		margin: 0 0 math.div(20, 46)*1em;
		font-weight: bold;
		color: $purple;

		@media screen and (max-width: 767px) {
			font-size: math.div(24, 16)*1em;
			line-height: 32/24;
			margin: 0 0 math.div(14, 24)*1em;
		}
	}

	h2 {
		color: $purple;
		font-size: math.div(28, 16)*1em;
		line-height: math.div(32, 28)*1em;
		margin: math.div(28, 28)*1em 0 math.div(14, 28)*1em;
		font-weight: bold;

		@media screen and (max-width: 767px) {
			font-size: math.div(20, 16)*1em;
			line-height: 26*0.05*1em;
			margin: 28*0.05*1em 0 12*0.05*1em;
		}
	}

	.quote {
		color: $purple;
		font-size: math.div(28, 16)*1em;
		line-height: 32/28;
		margin: math.div(28, 28)*1em 0 math.div(14, 28)*1em;
		font-weight: 300;
		font-style: italic;

		@media screen and (max-width: 767px) {
			font-size: math.div(20, 16)*1em;
			line-height: 26*0.05*1em;
			margin: 28*0.05*1em 0 12*0.05*1em;
		}
	}

	h3 {

		font-size: 1em;
		line-height: math.div(22, 16)*1em;
		margin: 0;
		font-weight: bold;

		@media screen and (min-width: 768px) {

			font-size: math.div(20, 16)*1em;
			line-height: 26*0.05*1em;
		}
	}

	p, ul, ol {

		margin: 0 0 math.div(22, 16)*1em;

		a {
			text-decoration: underline;
		}
	}
}

.body-content ~ .body-content.body-content--home {
	margin: 40px 0;

	.col-12 {
		& > *:last-child {
			margin-bottom: 0;
		}
	}
}
