@use "sass:math";
@use '../settings' as *;

.mynovi-data-table-container {
	margin: 40px 0;
}

.mynovi-data-table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	clear: both;
	position: relative;

	& + .button-bar {
		margin-top: 30px;

		@media screen and (max-width: 767px) {
			margin-top: 26px;
		}
	}

	th, td {
		text-align: left;
		padding: 20px 1em 20px 0;
		font-weight: normal;
		font-size: 1em;
		line-height: (math.div(22, 16) * 1em);
		border-bottom: 2px solid #eaeaea;
		vertical-align: middle;

		&:last-child {
			padding-right: 0;
		}

		p, .button-bar {
			margin: 0;
		}

		.button-bar {
			.button {
				margin: 5px 0 5px 22px;

				&:first-child {
					margin-left: 0;
				}
			}

		}
	}

	thead {
		th {
			font-size: (math.div(20, 16) * 1em);
			line-height: (24 * 0.05 * 1em);
			font-weight: bold;
		}
	}

	tbody {
		th {
			color: $purple;
		}
	}

	&_closing {
		&_text {
			display: none;
		}
	}
}

tr.mynovi-data-table__loading {
	display: none;

	&:only-child {
		display: table-row;
	}

	&:not(:only-child) {
		display: none !important;
		animation: none !important;
	}
}

td.mynovi-data-table_buttons {
	text-align: right;
	padding: 10px;

	.button-bar {
		text-align: right;
	}
}

@media screen and (max-width: 767px) {
	.mynovi-data-table.mynovi-data-table--pending {
		width: auto;
		margin: 0 -17px;

		thead {
			display: none;
		}

		&, tbody, tr, td, th {
			display: block;
			padding: 0;
		}

		tr {
			clear: both;
			position: relative;
			border-bottom: 2px solid #eaeaea;
			padding: 12px 15px 8px;

			&.mynovi-data-table__loading + tr,
			&:first-child {
				border-top: 2px solid #eaeaea;
			}

			&:after {
				content: '';
				display: table;
				clear: both;
			}
		}

		td {
			border: none;
		}

		.mynovi-data-table_datelocation {
			float: left;
			max-width: calc(100% - 5em);

			h4 {
				padding: 5px 0;
				color: $purple;
			}

			p {
				padding: 5px 0;
				color: $purple;
			}
		}

		.mynovi-data-table_volume {
			float: right;
			clear: right;
			padding-top: 37px;
			padding-bottom: 5px;
		}

		.mynovi-data-table_closing {
			float: right;
			clear: right;

			position: absolute;
			right: 15px;
			top: 12px;

			font-size: 13px;
			line-height: math.div(32, 13)*1em;

			&_text {
				display: inline-block;
			}
		}

		.mynovi-data-table_buttons {
			.button:not(:last-child) {
				display: none;
			}

			.button {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				opacity: 0;
				margin: 0;
			}
		}

	}
}

.mynovi-data-table.mynovi-data-table--history {
	min-height: 150px;

	tbody tr {
		.body-content:not(.loading) & {
			animation: tr-fade-in 0.2s ease-out;
		}
	}
}

@keyframes tr-fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media screen and (max-width: 767px) {
	.homepage-pricelists .table-container,
	.homepage-pending-orders .table-container {
		max-width: none;
		overflow: initial;
	}

	.mynovi-data-table.mynovi-data-table--pricelists,
	.homepage-pricelists .mynovi-data-table,
	.homepage-pending-orders .mynovi-data-table,
	.mynovi-data-table.mynovi-data-table--history {
		width: auto;
		margin: 0 -17px;

		thead {
			display: none;
		}

		&, tbody, tr, td, th {
			display: block;
			padding: 0;
		}

		tr {
			clear: both;
			position: relative;
			border-bottom: 2px solid #eaeaea;
			padding: 12px 15px 8px;

			&.mynovi-data-table__loading + tr,
			&:first-child {
				border-top: 2px solid #eaeaea;
			}

			&:after {
				content: '';
				display: table;
				clear: both;
			}
		}

		td {
			border: none;
		}

		.mynovi-data-table_datelocation {
			float: left;
			max-width: calc(100% - 7em);
			border: none;

			h4 {
				padding: 5px 0;
				color: $purple;
			}

			p {
				padding: 5px 0;
				color: $purple;
			}
		}

		.mynovi-data-table_name-and-availability {
			border: none;

			h4 {
				padding: 5px 0;
				color: $purple;
				font-size: 16px;
				line-height: math.div(22, 16)*1em;
			}

			p {
				padding: 5px 0;
				font-size: 13px;
				line-height: math.div(22, 13)*1em;
				color: #333333;
			}
		}

		.mynovi-data-table_description {
			line-height: math.div(22, 16)*1em;
			padding: 5px 0 8px;
		}

		.mynovi-data-table_available-for {
			display: none;
		}

		.mynovi-data-table_volume {
			float: right;
			clear: right;
			padding-top: 37px;
			padding-bottom: 5px;
		}

		.mynovi-data-table_closetime,
		.mynovi-data-table_status {
			float: right;
			clear: right;
			position: absolute;
			right: 15px;
			top: 12px;
			font-size: 13px;
			line-height: math.div(32, 13)*1em;

			&_text {
				display: inline-block;
			}
		}

		.mynovi-data-table_orderid {
			clear: left;
			line-height: math.div(32, 16)*1em;
		}

		.mynovi-data-table_buttons {
			//display: none;

			.button:not(:last-child) {
				display: none;
			}

			.button {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				opacity: 0;
				margin: 0;
			}
		}

	}
}
