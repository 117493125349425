@use "sass:math";

$footerDarkGrey: #4d4d4d !default;
$footerLightGrey: #b3b3b3 !default;

.footer {
	background: $footerDarkGrey;
	color: #fff;
	padding: 40px 0 0;
	margin: 40px 0 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	a {
		color: #fff;
	}

	h2 {
		color: #fff;
		font-size: math.div(20, 16)*1em;
		line-height: 32*0.05*1em;
		margin: 0 0 8*0.05*1em;

		@media screen and (min-width: 1024px) {
			margin: 0 0 12*0.05*1em;
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0 0 20px;

		&:after {
			content: '';
			display: table;
			clear: both;
		}

		li {
			list-style: none;
			padding: 5px 1em 5px 0;
			margin: 0;
			font-size: 1em;
			line-height: math.div(22, 16)*1em;
		}

		a {
			font-weight: bold;
			display: inline-block;
			text-decoration: none;
		}
	}

	p {
		a {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&.kvk-footer {
		padding: 5px 0 0;

		p {
			margin: 0;
		}

		.footer-disclaimer-menu {
			margin: 0;
		}
	}

	@media screen and (min-width: 1024px) {
		&.kvk-footer {
			.col-6 ul li {
				float: none;
				width: auto;
				padding-left: 0;
			}
		}
	}

	@media screen and (min-width: 768px) and (max-width: 1023px) {
		.footer-social {
			max-width: 200px;
		}
	}

	@media screen and (max-width: 767px) {
		.col-sm-12 h2 {

			@media screen and (max-width: 480px) {
				font-size: 16px;
			}
		}

		.col-sm-12 p,
		.col-sm-12 ul li {
			float: left;
			width: 50%;

			@media screen and (max-width: 480px) {
				font-size: 13px;
			}
		}

		.col-sm-12 ul li {
			&:nth-child(odd) {
				clear: left;
			}

			&:nth-child(even) {
				padding-left: 0;
			}
		}
	}
}

.footer-disclaimer-menu {
	background: $footerLightGrey;
	color: #333333;
	text-align: center;
	font-size: 1em;
	line-height: math.div(20, 16)*1em;
	padding: math.div(18, 16)*1em 0;
	margin: 30px 0 0;

	a {
		color: inherit;
		display: inline-block;
		margin-right: (math.div(24, 16)*1em);

		svg {
			margin-left: 4px;
		}
	}

	@media screen and (min-width: 1024px) {
		text-align: left;
		margin: 50px 0 0;

		.col-12 {
			float: right;
			width: auto;
			min-width: 25%;
			max-width: 100%;
		}

	}
}

.footer-social {
	clear: both;

	a {
		transition: opacity 0.2s ease-in-out;
		margin: 0 10px 10px 0;
		display: inline-block;

		@media screen and (min-width: 1024px) and (max-width: 1140px) {
			// Adjust margin to make the icons fit
			margin: 0 4px 10px 0;
		}

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			opacity: 0.9;
		}

		svg,
		svg use {
			pointer-events: none;
		}
	}

	@media screen and (max-width: 767px) {
		text-align: center;
	}
}

cookie-banner-wrapper {
	z-index: 999;
}
