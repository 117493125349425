@use "sass:math";

.home-spotlight-overview {
	@media screen and (min-width: 768px) {
		margin-top: 48px;
	}

}

.mynovi-data-table-link-to-mynovi {
	margin: math.div(22, 16)*1em 0 0;
}

.blog-brands-link-to-overview {
	margin: 1em 0 2em;
}

.homepage-pricelists,
.homepage-pending-orders,
.homepage-spotlight {
	h1 {
		margin-bottom: 10px;
	}
}

.blog-overview__header,
.homepage-brands__header {
	margin-top: 40px;
}

.homepage-pricelists,
.homepage-pending-orders {

	position: relative;

	& > .button-bar {

		@media screen and (min-width: 768px) {
			position: absolute;
			top: 20px;
			right: 0;
			margin: 0;
		}
	}

	.mynovi-intro {
		@media screen and (max-width: 767px) {
			margin-bottom: 16px;
		}
	}
}

.spotlight-overview {
	position: relative;
}

.homepage-pricelists,
.homepage-pending-orders {
	margin: 20px 0 40px;

	.main-content {
		min-height: 0;
	}
}

/**
	Hide elements from the pending orders / pricelists homepage blocks.
	These are generic components which are also shown in the webshop.
	These elements should be shown in the webshop, and to make the code less complex,
	We simply hide them with css in the website.
 */
.homepage-pending-orders {
	.button-bar--pending-orders-bottom,
	.button-bar--pending-orders-backtoproducts {
		display: none !important;
	}
}

.homepage-pricelists {
	.mynovi-intro {
		display: none;
	}
}