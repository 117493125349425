@use "sass:math";
@use "forms-select";
@use '../settings' as *;

$red-error: #D3001A !default;

label {
	font-weight: bold;
	display: inline-block;
	line-height: (math.div(22, 16) * 1em);
	padding: (math.div(22, 16) * 0.5em) 0;

	&[for] {
		cursor: pointer;
	}

	@media screen and (max-width: 767px) {
		padding-bottom: (math.div(8, 16) * 1em)
	}
}

input[type="date"],
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
textarea {
	height: (math.div(44, 16) * 1em);
	border-radius: 4px;
	background: #fff;
	border: 2px solid $grey-secondary;
	display: inline-block;
	max-width: 100%;

	padding-left: (math.div(14, 16) * 1em);
	padding-right: 1em;

	font-family: $helvetica;

	transition: border 0.15s ease-out;

	&:focus {
		border-color: $purple;
	}

	&.ng-invalid.ng-touched {
		box-shadow: none;
		border-color: $red-error;
		transition-duration: 0.1s;

		&:focus {
			border-color: $purple;
		}
	}

	&:invalid {
		box-shadow: none;
	}

	&.input--small {
		width: 4em;
		padding-right: 0;
		padding-left: 2px;
		text-align: center;
		margin-right: 4px;
		text-overflow: ellipsis;
	}

	&::-ms-clear {
		display: none;
	}
}

textarea {
	line-height: (math.div(22, 16) * 1em);

}

$input-placeholder-color: #919191;

::-webkit-input-placeholder {
	color: $input-placeholder-color;
}

:-ms-input-placeholder {
	color: $input-placeholder-color;
}

::-moz-placeholder {
	color: $input-placeholder-color;
}

.form-row {
	clear: both;
}

.form-label {
	@media screen and (min-width: 768px) {
		float: left;
		width: 150px;
		text-align: right;
		margin: 0 0 20px;

		&:last-of-type {
			margin: 0;
		}
	}
}

.form-input-container {
	margin: 0 0 20px 170px;
	position: relative;

	@media screen and (max-width: 767px) {
		margin: 0 0 5px;

		&:last-of-type {
			margin: 0 0 5px;
		}
	}

	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="number"],
	input[type="tel"],
	textarea {
		width: 100%;
		display: block;
	}
}

.form-input-error {
	font-size: (math.div(14, 16) * 1em);
	line-height: (math.div(22, 14) * 1em);
	color: $red-error;
	margin: 0;
	padding: 10px 0;
}

input[type="checkbox"],
input[type="radio"] {

	opacity: 0.01;
	transform: scale(0.01);
	position: absolute;

	&:focus {
		//opacity: 1;
		//transform: scale(1);

	}

	label & {
		opacity: 0;
		position: absolute;
		width: 1px;
		height: 1px;
		visibility: hidden;
	}
}

.checkbox-icon {
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 20px;
	border: 2px solid #CCCCCC;

	line-height: 18px;
	text-align: center;
	color: #fff;

	border-radius: 4px;
	margin: 0 5px 0 0;

	position: relative;
	top: -2px;

	transition: color 0s ease-out 0.3s;

	svg {
		position: relative;
		top: -1px;
		left: 1px;

		opacity: 0;
		transform: scale(0);
		transition: transform 0.2s ease-out, opacity 0.3s ease-out;

		transform-origin: 50% 75%;
	}
}

.checkbox-icon.checkbox-icon--radio,
label .checkbox-icon.checkbox-icon--radio,
input[type="radio"] ~ .checkbox-icon,
input[type="radio"] + label .checkbox-icon {
	border-radius: 50%;

	&:before {
		content: '';
		display: block;
		margin: 3px auto 0;
		width: 10px;
		height: 10px;
		background: $green;
		border-radius: 50%;
		opacity: 0;
		transform: scale(0);
	}
}

input:focus ~ .checkbox-icon,
input:focus + label .checkbox-icon {
	border-color: #919191;
}

.checkbox-icon.checked,
label .checkbox-icon.checked,
input:checked ~ .checkbox-icon,
input:checked + label .checkbox-icon {
	color: $green;
	transition-delay: 0s;
	transition-duration: 0s;

	&:before,
	svg {
		opacity: 1;
		transform: scale(1);
		transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.05s ease-in;
	}
}

.formModuleForm {
	input:checked ~ .checkbox-icon {
		color: $green;
		transition-delay: 0s;
		transition-duration: 0s;

		&:before {
			display: none;
		}
	}

	input:checked + .checkbox-icon {
		color: $green;
		transition-delay: 0s;
		transition-duration: 0s;

		&:before {
			display: block;
			opacity: 1;
			transform: scale(1);
			transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.05s ease-in;
		}
	}
}
