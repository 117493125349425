zxing-scanner {
	position: relative;
	height: 100% !important;
	width: 100% !important;
	z-index: 100;

	video {
		height: 100% !important;
		width: 100% !important;
	}
}
