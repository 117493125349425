@use "sass:math";

.homepage-brands {
	margin-top: 34px;
	@media screen and (max-width: 768px) {
		margin-top: 14px;
	}
}

.homepage-brands-item {

	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

.homepage-brands-item__title {
	margin: 12px 0 5px;
	font-size: math.div(20, 16)*1em;
	line-height: 24*0.05*1em;

	@media screen and (max-width: 768px) {
		font-size: math.div(18, 16)*1em;
	}

	@media screen and (max-width: 640px) {
		font-size: math.div(16, 16)*1em;
	}

	@media screen and (max-width: 520px) {
		font-size: math.div(13, 16)*1em;
	}
}

@media screen and (max-width: 767px) {

	.homepage-brands-item {
		margin-bottom: 20px;
	}

	.homepage-brands-item__image {
		float: left;
		min-width: 100px;
		max-width: 30%;
		margin-right: 20px;
	}

	.homepage-brands-item__text-container {
		margin-left: 33%;
		margin-left: calc(30% + 20px);
		padding-top: 8px;
	}

	.homepage-brands-item__title {
		margin: 0;
	}

	.homepage-brands-item__intro,
	.homepage-brands-item__readmore {
		display: none;
	}
}