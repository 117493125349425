.inspiration-collage {
	&__wrapper {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-between;
		height: auto;
		overflow: visible;
		margin: 20px 0;

		@media screen and (min-width: 1024px) {
			height: 445px;
			overflow: hidden;
		}
	}
}

.inspiration-collage__wrapper {
	&.grid-gutter {
		.inspiration-collage-block--big {
			padding-right: 0;

			@media screen and (min-width: 1024px) {
				padding-right: 15px;
			}

			&:last-child {
				padding-left: 0;
				padding-right: 0;
				margin-top: 30px;

				@media screen and (min-width: 1024px) {
					margin-top: 0;
					padding-left: 15px;
					padding-right: 0;
				}
			}
		}

		.inspiration-collage-block--small {
			padding-left: 0;

			@media screen and (min-width: 1024px) {
				padding-left: 15px;
			}

			/// If a small block is the first child of the collage then
			/// we set the padding to the right for that first child
			&:first-child {
				padding-left: 0;
				padding-right: 0;

				@media screen and (min-width: 1024px) {
					padding-left: 0;
					padding-right: 15px;
				}

				/// Select it's sibling element with the small class and add
				/// the same padding from the right.
				& + .inspiration-collage-block--small {
					padding-left: 0;
					padding-right: 0;

					@media screen and (min-width: 1024px) {
						padding-left: 0;
						padding-right: 15px;
					}
				}
			}
		}
	}
}

.inspiration-collage-block {
	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}

	&__container {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;

		.inspiration-collage-block__image {
			img {
				@media screen and (min-width: 1024px) {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}

	&__content {
		&--inner {
			p {
				margin: 0;

				@media screen and (min-width: 1024px) {
					margin: 0 0 1.25em;
				}
			}

			.button {
				margin-top: 20px;

				&--custom-outline {
					color: inherit;
					border-color: inherit;
					background-color: transparent;
					box-shadow: none;
					transition: all 0.3ms ease-in-out;

					&:hover {
						color: inherit;
						border-color: inherit;
						background-color: transparent;
						box-shadow: none;
						opacity: 0.7;
					}
				}
			}
		}
	}

	&--big {
		width: 100%;
		flex: 1 0 100%;

		@media all and (-ms-high-contrast:none) {
			min-height: 450px;
		}

		@media screen and (min-width: 1024px) {
			width: 70%;
			flex: 0 0 100%;
		}

		@media screen and (min-width: 1024px) and (-ms-high-contrast:none) {
			min-height: auto;
		}

		figure {
			height: 100%;
			margin: 0;
		}
	}

	&--small {
		flex: 1 0 100%;
		width: 100%;
		margin: 30px 0 0 0;
		overflow: hidden;

		@media all and (-ms-high-contrast:none) {
			min-height: 450px;
		}

		@media screen and (min-width: 1024px) {
			flex: 0 0 30%;
			width: 30%;
			margin: 0;
			min-height: 210px;
		}

		@media screen and (min-width: 1024px) and (-ms-high-contrast:none) {
			min-height: 210px;
		}

		figure {
			width: 100%;
			object-fit: cover;
			object-position: 50% 50%;
			height: 100%;
			margin: 0;
		}
	}
}
